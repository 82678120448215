import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { MdEdit, MdOutlineDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaWhatsapp, FaDownload } from "react-icons/fa";
import axios from "axios";
import edMantra from "../../services/edMantra";
import companyUpload from "../../assets/files/ATSCompany.xlsx";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SiMicrosoftexcel } from "react-icons/si";
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import _ from "lodash";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import loadinggif from "../../assets/images/loading.gif";
import {
  FaFileDownload,
  FaFileExport,
  FaAddressCard,
  FaCloudUploadAlt,
  FaFilter,
} from "react-icons/fa";

const CollabrationTool = (props) => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const [orgAgreeName, setorgAgreeName] = useState(null);
  const [showModal, setShow] = useState(false);
  const [showDocModal, setShowDoc] = useState(false);
  const [selectedRecruiterId, setRecruiterId] = useState(null);
  const handleShow = (typeObj) => setShow(typeObj);
  const [allCompany, setAllCompany] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [file, setFile] = useState({});
  const [allVacancy, setAllVacancy] = useState([]);
  const fileInputRef = useRef();
  const [toggle, setToggle] = useState(false);
  const [allRecruiters, setAllRecruiters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [orgRate, setOrgRate] = useState("");
  const [docUrl, setDocUrl] = useState('');
  const [company, setCompany] = useState({
    primaryPOCName: "",
    primaryPOCMobile: "",
    primaryPOCEmail: "",
    primaryPOCDesignation: "",
    secondaryPOCName: "",
    secondaryPOCMobile: "",
    secondaryPOCEmail: "",
    secondaryPOCDesignation: "",
    companyName: "",
    orgCategory: "",
    orgRate: "",
    orgAgree: "",
  });

  // const [vacancy, setVacancy] = useState({
  //   companyId: "",
  //   vacancyStatus: "",
  //   jobProfile: "",
  //   sector: "",
  //   location: "",
  //   noOfVacancy: "",
  //   salaryRangeMin: "",
  //   salaryRangeMax: "",
  //   experience: "",
  //   genderpref: "",
  //   dateOfCreation: "",
  //   recruitementManager: "",
  //   jobDiscription: "",
  //   pdcDate: "",
  //   tANDc: "",
  //   createdBy: data?.empId,
  // });

  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    setCompany({
      primaryPOCName: "",
      primaryPOCMobile: "",
      primaryPOCEmail: "",
      primaryPOCDesignation: "",
      secondaryPOCName: "",
      secondaryPOCMobile: "",
      secondaryPOCEmail: "",
      secondaryPOCDesignation: "",
      companyName: "",
      orgCategory: "",
      orgRate: "",
      orgAgree: "",
    });
  };

  const handleBulkAssign = async (createdBy) => {

    try {

      const result = await edMantra.bulkAssign({ createdBy, companyList: selectedRows })
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data.message)
        setRefresh(true)
      }

      else {
        toast.error(result?.data?.message)
      }

    } catch (error) {

      console.error(error)

    }




  }

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (e, index) => {
    setFile(e.target.files[0]);
    setToggle(!toggle);
  };


  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("companyfile", file);
    formData.append("empId", data?.empId);
    try {
      const result = await edMantra.uploadCompany(formData);
      if (result.data.errorCode === 0) {
        setToggle(false);
        setRefresh(!refresh);
        toast(result?.data?.message);
        setFile({});
      }
    }

    catch (error) {
      // Default error message
      let errorMessage = "The phone number you entered is already registered. Please use a different phone number.";

      // Check for a specific error response from the backend (response.data.message)
      if (error?.response?.data?.message) {
        if (error.response.data.message.includes("Duplicate phone number found")) {
          errorMessage = "The phone number you entered is already registered. Please use a different phone number.";
        }
      }

      toast.error(errorMessage);
      console.error("Error uploading files:", error);
    }
  };

  const handleOnchange = (value, valueField) => {
    setCompany((prev) => ({
      ...prev,
      [valueField]: value,
    }));

    if (valueField === "orgCategory") {
      setSelectedCategory(value);
    }

    if (valueField === "orgRate") {
      setOrgRate(value);
    }
  };


  // const handleVacancyChange = (value, valueField) => {
  //   setVacancy((prev) => ({
  //     ...prev,
  //     [valueField]: value,
  //   }));
  // };

  const getAllCompany = async () => {
    try {
      const result = await edMantra.getAllCompany({ empId: data?.empId });
      setAllCompany([]);
      if (result?.data?.errorCode === 0) {
        setAllCompany(result?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllVacancy = async () => {
    try {
      const result = await edMantra.getAllVacancy({ empId: data?.empId });
      setAllVacancy([]);

      if (result?.data?.errorCode === 0) {
        setAllVacancy(result?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateCompany = async () => {
    const formData = new FormData();
    formData.append("companyId", company.companyId);
    formData.append("orgAgreeDoc", orgAgreeName);
    formData.append("primaryPOCName", company.primaryPOCName);
    formData.append("primaryPOCMobile", company.primaryPOCMobile);
    formData.append("primaryPOCEmail", company.primaryPOCEmail);
    formData.append("primaryPOCDesignation", company.primaryPOCDesignation);
    formData.append("secondaryPOCName", company.secondaryPOCName);
    formData.append("secondaryPOCMobile", company.secondaryPOCMobile);
    formData.append("secondaryPOCEmail", company.secondaryPOCEmail);
    formData.append("secondaryPOCDesignation", company.secondaryPOCDesignation);
    formData.append("companyName", company.companyName);
    formData.append("orgCategory", company.orgCategory);
    formData.append("orgRate", company.orgRate);
    formData.append("createdBy", data?.empId);


    try {
       const response = await fetch("https://mantraserp.com/api/updateCompanyById", // for live production
     // const response = await fetch("http://localhost:5000/api/updateCompanyById", // for dev env.

        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const result = await response.json();
      if (result?.errorCode === 0) {
        setShow(false);
        toast.success(result?.message);
        getAllCompany();
        setShow(false);
        setCompany({
          primaryPOCName: "",
          primaryPOCMobile: "",
          primaryPOCEmail: "",
          primaryPOCDesignation: "",
          secondaryPOCName: "",
          secondaryPOCMobile: "",
          secondaryPOCEmail: "",
          secondaryPOCDesignation: "",
          companyName: "",
          orgCategory: "",
          orgRate: "",
          orgAgreeName: "",
        });
      }
    }
    catch (error) {
      // Default error message
      let errorMessage = "An unexpected error occurred. Please try again later.";

      // Convert the error message to lowercase for easier matching
      const errorMsgLower = error.message.toLowerCase();

      if (errorMsgLower.includes("failed to upload files")) {
        errorMessage = "The phone number you entered is already registered. Please use a different phone number.";
      } else if (errorMsgLower.includes("phone must be unique")) {
        errorMessage = "This phone number is already associated with an existing record. Please use a different phone number.";
      } else if (errorMsgLower.includes("network error")) {
        errorMessage = "Unable to register the candidate at the moment. Please check your network or try again.";
      } else if (errorMsgLower.includes("companyid") && errorMsgLower.includes("undefined")) {
        errorMessage = "The company ID is missing or invalid. Please provide a valid company ID and try again.";
      }

      // Show error message in UI
      toast.error(errorMessage);

      // Log full error details for troubleshooting
      console.error("Error:", error);
    }

  };

  const getAllRecruiters = async () => {
    try {
      const res = await edMantra.getAllRecruiters();
      setAllRecruiters([]);

      if (res?.data?.errorCode === 0) {
        setAllRecruiters(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("orgAgreeDoc", orgAgreeName);
    formData.append("primaryPOCName", company.primaryPOCName);
    formData.append("primaryPOCMobile", company.primaryPOCMobile);
    formData.append("primaryPOCEmail", company.primaryPOCEmail);
    formData.append("primaryPOCDesignation", company.primaryPOCDesignation);
    formData.append("secondaryPOCName", company.secondaryPOCName);
    formData.append("secondaryPOCMobile", company.secondaryPOCMobile);
    formData.append("secondaryPOCEmail", company.secondaryPOCEmail);
    formData.append("secondaryPOCDesignation", company.secondaryPOCDesignation);
    formData.append("companyName", company.companyName);
    formData.append("orgCategory", company.orgCategory);
    formData.append("orgRate", company.orgRate);
    formData.append("createdBy", data?.empId);


    try {
       const response = await fetch("https://mantraserp.com/api/addCompany", // for live production
     // const response = await fetch("http://localhost:5000/api/addCompany", // for dev env.

        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const result = await response.json();
      if (result?.errorCode === 0) {
        setShow(false);
        toast.success(result?.message);
        getAllCompany();
        setShow(false);
        setCompany({
          primaryPOCName: "",
          primaryPOCMobile: "",
          primaryPOCEmail: "",
          primaryPOCDesignation: "",
          secondaryPOCName: "",
          secondaryPOCMobile: "",
          secondaryPOCEmail: "",
          secondaryPOCDesignation: "",
          companyName: "",
          orgCategory: "",
          orgRate: "",
          orgAgreeName: "",
        });
      }
    }
    catch (error) {
      // User-friendly error messages
      let errorMessage = "An unexpected error occurred. Please try again later.";

      if (error.message.includes("Failed to upload files")) {
        errorMessage = "The phone number you entered is already registered. Please use a different phone number.";
      } else if (error.message.includes("phone must be unique")) {

        errorMessage = "Unable to register the candidate at the moment. Please check your network or try again.";
      }

      toast.error(errorMessage);
      console.error("Error uploading files:", error);
    }
  };


  const handleViewDoc = (docName) => {
    // Build a complete URL to the document
    const url = `${window.location.origin}/orguploads/${docName}`; // Adjust based on your deployment
    console.log("Document URL:", url); // Debug log to verify the URL
    setDocUrl(url);
    setShowDoc(true);
  };
  
  const handleCloseDoc = () => {

    setShowDoc(false);
    setDocUrl('');
  }


  const handleAadharDownlaod = async (fileName) => {
    try {
      if (!fileName) {
        console.error('No file to download');
        return;
      }

      const encodedFileName = encodeURIComponent(fileName); // Encode filename
      //const response = await axios.post('http://localhost:5000/api/orgdownload',
          const response = await axios.post('https://mantraserp.com/api/orgdownload',
        { filename: encodedFileName },
        { responseType: 'blob' } // Important for binary data
      );

      // Create a URL for the blob and trigger a download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Set the default file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Download error:', error);
    }
  };





  // const handleVacancySubmit = async () => {
  //   try {
  //     const result = await edMantra.addVacancy(vacancy)
  //     if (result?.data.errorCode === 0) {
  //       setShow(false);
  //       toast.success(result?.message);
  //       getAllCompany();
  //       setShow(false);
  //       setVacancy({
  //         vacancyStatus: "",
  //         jobProfile: "",
  //         sector: "",
  //         location: "",
  //         noOfVacancy: "",
  //         salaryRangeMin: "",
  //         salaryRangeMax: "",
  //         experience: "",
  //         genderpref: "",
  //         dateOfCreation: "",
  //         jobDiscription: "",
  //         pdcDate: "",
  //         tANDc: "",
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }


  // };


  const handleEdit = (row) => {
    setCompany(row);
    setShow(true);
    setIsEdit(true);
  };


  const openWhatsApp = async (primaryPOCMobile) => {
    try {
      let phone;
      // Ensure the phone number starts with +91
      if (!primaryPOCMobile.startsWith("+91")) {
        phone = "+91" + primaryPOCMobile;
      } else {
        phone = primaryPOCMobile;
      }
      // Construct the WhatsApp URL
      const whatsappUrl = `https://wa.me/${phone}`;
      // Open the URL in a new tab
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };


  const handleDelete = async (companyId) => {
    try {
      const formData = { companyId };  // Ensure formData is an object with companyId
      const res = await edMantra.deleteCompanyById(formData);

      if (res.data?.errorCode === 0) {
        toast.success(res?.data?.message);
        getAllCompany();
      }
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAllCompany();  // Make sure this updates allCompany state
      } finally {
        setLoading(false);  // Set loading to false after fetching
      }
    };

    fetchData();
  }, [refresh]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const recruitersData = await getAllRecruiters();  // Fetch data
        if (recruitersData && recruitersData.length > 0) {
          setAllRecruiters(recruitersData);  // Update state only if data is available
        } else {
          console.log("No recruiters found");
        }
      } finally {
        setLoading(false);  // Set loading to false after fetching
      }
    };

    fetchData();
  }, [refresh]);



  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
      filter: textFilter(),
      filter: textFilter(),

    },
    {
      dataField: "primaryPOCMobile",
      text: "Primary POC Number",
      sort: true,
      filter: textFilter(),

    },
    {
      dataField: "orgCategory",
      text: "Org. Category",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "orgRate",
      text: "Org. Fees",
      sort: true,
      filter: textFilter(),
    },

    {
      dataField: "orgAgree",
      text: "Org. Agreement",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row) => (
        <div className="d-flex">
          <a href="#" onClick={() => handleAadharDownlaod(row.orgAgree)}>{row.orgAgree} | <FaDownload/></a>
          {/* <span style={{ margin: '0 10px' }}>|</span>
          <a href="#" onClick={() => handleViewDoc(row.orgAgree)}>View</a> */}
        </div>


      ),
    },
    {
      dataField: "createdBy",
      text: "Recruitment Manager",
      filter: selectFilter({
        options: allRecruiters.length > 0 ? allRecruiters.map(emp => ({
          value: emp.empId,
          label: `${emp.fname} ${emp.lname}`,
        })) : [],
        placeholder: 'Select or Search by Name',
      }),
      formatter: (cell, row) => {
        const itemObj = allRecruiters.find(emp => emp.empId === row.createdBy);
        console.log(itemObj, "ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZz")
        return (
          <div className="d-flex">
            {itemObj ? (
              <p>{`${itemObj?.fname} ${itemObj?.lname}`}</p>
            ) : (
              loading ? (
                <p>Loading...</p> // Show loading while data is being fetched
              ) : (
                <p>Data not available</p> // Fallback when data is not found
              )
            )}
          </div>
        );
      },
    },


    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">



          <FaWhatsapp
            onClick={() => openWhatsApp(row?.primaryPOCMobile)}
            size={20}
            color="limegreen"
          />

          <MdEdit
            size={25}
            color="black"
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => handleEdit(row)}
          />
          {data?.role?.roleName === "SuperAdmin" && (
            <MdOutlineDelete
              size={25}
              color="red"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.companyId)}
            />



          )}

        </div>
      ),
    },

  ];



  const mergedVacancies = allVacancy.map((vacancy) => {
    const recruiter = allRecruiters.find(
      (recruiter) => String(recruiter.empId) === String(vacancy.createdBy)
    );

    // Log recruiter for debugging
    console.log('Recruiter Found:', recruiter);

    return {
      ...vacancy,
      name: recruiter ? `${recruiter.fname || ''} ${recruiter.lname || ''}`.trim() : 'Unknown'
    };
  });

  console.log('Merged Vacancies:', mergedVacancies);



  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgcolor: 'red'
  };

  // Function to handle row selection
  const handleRowSelect = (row, isSelected) => {

    setSelectedRows([...selectedRows, row])


  }; console.log(selectedRows)

  // Function to handle row selection for all rows
  const handleRowSelectAll = (isSelected, rows) => {
    // Update selectedRows state when all rows are selected or deselected
    console.log(rows)
    if (isSelected) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };





  useEffect(() => {
    const fetchData = async () => {
      try {
        const recruitersData = await getAllRecruiters();  // Fetch data
        if (recruitersData && recruitersData.length > 0) {
          setAllRecruiters(recruitersData);  // Update state only if data is available
        } else {
          console.log("No recruiters found");
        }
      } finally {
        setLoading(false);  // Set loading to false after fetching
      }
    };

    fetchData();
  }, []);



  console.log(allRecruiters, "Badiya si string ")

  return (
    <>



      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      <div className="container-fluid">
        <div className="row">


          <div className="col-md-4 card border-0">
            <p className="fs-3 fw-normal typed-text">Manage Company</p>
          </div>

          <div className="card col-md-6 col-sm-12 border-0">
            <div className="d-flex flex-wrap align-items-center">
              {toggle ? (
                <button
                  type="button"
                  className="btn btn-warning btn-sm mr-2"
                  onClick={handleUpload}
                >
                  <FaCloudUploadAlt color={"black"} size={20} /> Upload
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-warning btn-sm mr-2"
                    onClick={handleButtonClick}
                  >
                    <FaFileDownload color={"black"} size={20} /> <span className="">Import</span>
                  </button>

                  <a href={companyUpload} download className="btn btn-warning btn-sm mr-2">
                    <FaFileExport color={"black"} size={20} /> Sample File
                  </a>

                  <button
                    type="button"
                    className="btn btn-warning btn-sm"
                    onClick={() => handleShow(true)}
                  >
                    <FaAddressCard color={"black"} size={20} /> Add Company
                  </button>

                </>
              )}


              {data?.role?.roleName === "SuperAdmin" ? (
                <>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      // value={selectedRecruiterId}
                      onChange={(e) =>
                        handleBulkAssign(e.target.value)
                      }
                      required
                    >
                      <option value="Not assigned">
                        Select Recruitment Manager
                      </option>
                      {allRecruiters.map((item, index) => (
                        <option key={index} value={item.empId}>
                          {`${item.fname} ${item.lname}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : null}

            </div>
          </div>



          <div className="card col-md-12 border-0">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src={loadinggif} alt="Loading" />
              </div>
            ) : (
              <ToolkitProvider
                keyField="companyId"
                data={allCompany}
                columns={columns}
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
              >
                {
                  props => (
                    <div>
                      <ExportCSVButton className="btn btn-success btn-sm mb-2 float-end" {...(props.csvProps || {})} filename="MantrasERP_ATS_Companies_Report.csv">< SiMicrosoftexcel /> Export Record</ExportCSVButton>

                      <div className="table-responsive cus-table">
                        <BootstrapTable
                          {...props.baseProps}
                          selectedRows={selectedRows}
                          bootstrap4
                          keyField="companyId"
                          data={allCompany}
                          columns={columns}
                          striped
                          hover
                          condensed
                          filter={filterFactory()}
                          pagination={paginationFactory({ showTotal: true, })}
                          selectRow={{
                            mode: 'checkbox',
                            clickToSelect: true,
                            onSelect: handleRowSelect,
                            onSelectAll: handleRowSelectAll,
                          }}
                          filterPosition="top"
                        />
                      </div>

                      <Modal show={showDocModal} onHide={handleCloseDoc} size="lg">
                        <Modal.Header closeButton>
                          <Modal.Title>View Document</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {docUrl.endsWith('.pdf') ? (
                            <iframe src={docUrl} width="100%" height="500px" />
                          ) : (
                            <img src={docUrl} alt="Document Preview" style={{ width: '100%' }} />
                          )}
                        </Modal.Body>
                      </Modal>
                    </div>
                  )
                }
              </ToolkitProvider>
            )}
          </div>



        </div>
      </div>
      <Modal show={showModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* {showModal?.state && showModal?.type === "ADD_COMPANY" ? (
              <p>Company Registration Form</p>
            ) : (
              <p>Vacancy Form</p>
            )} */}

            <p>Edit Company</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          {/* {isEdit ? ( */}
          <>
            <h6 className="custom-h6">Organization Information</h6>
            <div className="row">
              <div className="col-md-6">
                <p className="mb-0 fs-6"> Comapny Name</p>
                <input
                  placeholder="Comapny Name"
                  className="form-control py-0 "
                  type="text"
                  value={company.companyName}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "companyName")
                  }
                />
              </div>
              <div className="col-md-6">
                <p className="mb-0 fs-6">Category</p>
                <select
                  name=""
                  id=""
                  className="form-control"
                  value={company.orgCategory}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "orgCategory")
                  }
                >
                  <option value={null}>Select Category</option>
                  <option value={"Silver"}>Silver</option>
                  <option value={"Platinum"}>Platinum</option>
                  <option value="Agreement Pending">Agreement Pending</option>
                </select>
              </div>
              {(selectedCategory === "Platinum" || selectedCategory === "Silver") && (
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>Rate</label>
                    <input
                      type="text"
                      className="form-control"
                      value={company.orgRate}
                      placeholder="Agreemented Rate"
                      onChange={(e) =>
                        handleOnchange(e.target.value, "orgRate")
                      }
                    />
                  </div>
                </div>
              )}

              {(selectedCategory === "Platinum" || selectedCategory === "Silver") && (
                <div className="col-md-12 mt-2">
                  <p className="mb-0 fs-6">Org. Agreement</p>
                  <input
                    className="form-control py-0"
                    type="file"
                    accept=".pdf,.doc,.docs"
                    name="orgAgreeDoc"
                    onChange={(e) => setorgAgreeName(e.target.files[0])}
                  />
                </div>
              )}
            </div>

            <h6 className="custom-h6 mt-5">Primary Point of Contact</h6>
            <div className="row">
              <div className="col-md-4">
                <p className="mb-0 fs-6"> Name</p>
                <input
                  placeholder="Name"
                  className="form-control py-0 "
                  type="text"
                  value={company.primaryPOCName}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "primaryPOCName")
                  }
                />
              </div>
              <div className="col-md-4">
                <p className="mb-0 fs-6">Contact No</p>
                <input
                  placeholder="Contact Number"
                  className="form-control py-0 "
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  value={company.primaryPOCMobile}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "primaryPOCMobile")
                  }
                />
              </div>
              <div className="col-md-4">
                <p className="mb-0 fs-6"> Email Address</p>
                <input
                  placeholder="Enter Email Address"
                  className="form-control py-0 "
                  type="email"
                  value={company.primaryPOCEmail}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "primaryPOCEmail")
                  }
                />
              </div>
              <div className="col-md-12 mt-2">
                <p className="mb-0 fs-6"> Designation</p>
                <input
                  placeholder="Designation"
                  className="form-control py-0 "
                  type="text"
                  value={company.primaryPOCDesignation}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "primaryPOCDesignation")
                  }
                />
              </div>
            </div>

            <h6 className="custom-h6 mt-5">Secondary Point of Contact</h6>
            <div className="row">
              <div className="col-md-4">
                <p className="mb-0 fs-6"> Name</p>
                <input
                  placeholder="Name"
                  className="form-control py-0 "
                  type="text"
                  value={company.secondaryPOCName}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "secondaryPOCName")
                  }
                />
              </div>
              <div className="col-md-4">
                <p className="mb-0 fs-6">Contact No</p>
                <input
                  placeholder="Contact Number"
                  className="form-control py-0 "
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  value={company.secondaryPOCMobile}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "secondaryPOCMobile")
                  }
                />
              </div>
              <div className="col-md-4">
                <p className="mb-0 fs-6"> Email Address</p>
                <input
                  placeholder="Enter Email Address"
                  className="form-control py-0 "
                  type="email"
                  value={company.secondaryPOCEmail}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "secondaryPOCEmail")
                  }
                />
              </div>
              <div className="col-md-12 mt-2">
                <p className="mb-0 fs-6"> Designation</p>
                <input
                  placeholder="Designation"
                  className="form-control py-0 "
                  type="text"
                  value={company.secondaryPOCDesignation}
                  onChange={(e) =>
                    handleOnchange(e.target.value, "secondaryPOCDesignation")
                  }
                />
              </div>
            </div>
          </>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} size="sm">
            Close
          </Button>
          {isEdit ?

            // showModal?.state && showModal?.type === "ADD_COMPANY" ? (
            <Button variant="primary" onClick={handleUpdateCompany} size="sm">
              Update Company
            </Button>
            : <Button variant="primary" onClick={handleSubmit} size="sm">
              Add Company
            </Button>
            //     <Button variant="primary" onClick={handleUpdateCompany} size="sm">
            //       Update Vacancy
            //     </Button>
            //   )
            // ) : showModal?.state && showModal?.type === "ADD_COMPANY" ? (
            //   <Button variant="primary" onClick={handleSubmit} size="sm">
            //     Add Company
            //   </Button>
            // ) : (
            //   <Button variant="primary" onClick={handleVacancySubmit} size="sm">
            //     Add Vacancy
            //   </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CollabrationTool;
