import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { MdEdit, MdOutlineDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from 'moment';
import { FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import edMantra from "../../services/edMantra";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SiMicrosoftexcel } from "react-icons/si";
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import _ from "lodash";
import filterFactory, {
  textFilter,
  dateFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import loadinggif from "../../assets/images/loading.gif";
import {
  FaFileDownload,
  FaFileExport,
  FaAddressCard,
  FaCloudUploadAlt,
  FaFilter,
  FaExclamationTriangle,
} from "react-icons/fa";

const VacancyManagement = (props) => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const [orgAgreeName, setorgAgreeName] = useState(null);
  const [showModal, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const handleShow = (typeObj) => setShow(typeObj);
  const [allCompany, setAllCompany] = useState([]);
  const [allVacancy, setAllVacancy] = useState([]);
  const [allRecruiters, setAllRecruiters] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [orgRate, setOrgRate] = useState("");


  const [vacancy, setVacancy] = useState({
    companyId: "",
    primaryPOCMobile: "",
    vacancyStatus: "",
    jobProfile: "",
    sector: "",
    location: "",
    noOfVacancy: "",
    salaryRangeMin: "",
    salaryRangeMax: "",
    experience: "",
    genderpref: "",
    dateOfCreation: "",
    recruitementManager: "",
    jobDiscription: "",
    pdcDate: "",
    tANDc: "",
    createdBy: data?.empId,
  });

  const handleClose = () => {
    setShow(false);
    setIsEdit(false);

  };




  const handleVacancyChange = (value, valueField) => {
    setVacancy((prev) => ({
      ...prev,
      [valueField]: value,
    }));
  };

  const getAllCompany = async () => {
    try {
      const result = await edMantra.getAllCompany({ empId: data?.empId });
      setAllCompany([]);
      if (result?.data?.errorCode === 0) {
        setAllCompany(result?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllVacancy = async () => {
    try {
      const result = await edMantra.getAllVacancy({ empId: data?.empId });
      setAllVacancy([]);

      if (result?.data?.errorCode === 0) {
        setAllVacancy(result?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllRecruiters = async () => {
    try {
      setLoading(true); // Start loading state
      const res = await edMantra.getAllRecruiters();
      setAllRecruiters([]);

      if (res?.data?.errorCode === 0) {
        setAllRecruiters(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // End loading state
    }
  };

  const handleUpdateVacancy = async () => {
    try {
      const res = await edMantra.updateVacancyById(vacancy);
      if (res?.data?.errorCode === 0) {
        toast.success(res?.data?.message);
        getAllCompany();
        setShow(false);
        setIsEdit(false);

      }
    } catch (error) { }
  };





  const handleAadharDownlaod = async (fileName) => {
    console.log(fileName, "FileFileFileFileFileFile")
    try {
    //  const response = await axios.post('http://localhost:5000/api/orgdownload',
        const response = await axios.post('https://mantraserp.com/api/orgdownload',
        { filename: fileName },
        { responseType: 'blob' } // Important for binary data
      );

      // Create a URL for the blob and trigger a download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Set the default file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Download error:', error);
    }
  };


  const handleVacancySubmit = async () => {
    try {
      const result = await edMantra.addVacancy(vacancy)
      if (result?.data.errorCode === 0) {
        setShow(false);
        toast.success(result?.message);
        getAllCompany();
        setVacancy({
          primaryPOCMobile: "",
          vacancyStatus: "",
          jobProfile: "",
          sector: "",
          location: "",
          noOfVacancy: "",
          salaryRangeMin: "",
          salaryRangeMax: "",
          experience: "",
          genderpref: "",
          dateOfCreation: "",
          jobDiscription: "",
          pdcDate: "",
          tANDc: "",
        });
        setReload(true);
      }
    } catch (error) {
      console.error(error);
    }


  };


  const handleEdit = (row) => {
    setVacancy(row);
    setShow(true);
    setIsEdit(true);
  };

  const handleDelete = async (vacancyId) => {
    try {
      const res = await edMantra.deleteVacancyById({ vacancyId });
      if (res.data?.errorCode === 0) {
        toast.success(res?.data?.message);
        getAllVacancy();
      }
    } catch (error) {
      console.error(error);
    }
  };



  const formatDateforleadinout = (dateString) => { //for lead craetedAt and updatedAt
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };
  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "companyName",
      text: "Company Name",
      sort: "true",
      filter: textFilter(),
      // formatter: (cell, row) => {
      //   const itemObj = allCompany?.find(
      //     (item) => item?.companyId === row?.companyId
      //   );

      //   return (
      //     <div className="d-flex">
      //       <>
      //         <p>{itemObj?.companyName}</p>
      //       </>
      //     </div>
      //   );
      // },

    },

    {
      dataField: "primaryPOCMobile",
      text: "Primary POC Mobile",
      sort: "true",
      filter: textFilter(),

    },

    {
      dataField: "jobProfile",
      text: "Job Profile ",
      sort: "true",
      filter: textFilter(),
    },
    {
      dataField: "vacancyStatus",
      text: "Vacancy Status",
      sort: "true",
      filter: textFilter(),
    },
    {
      dataField: "location",
      text: "Location ",
      sort: "true",
      filter: textFilter(),
    },

    {
      dataField: "sector",
      text: "Sector",
      sort: "true",
      filter: textFilter(),
    },


    {
      dataField: "createdAt",
      text: "Date of Creation",
      sort: "true",
      filter: dateFilter(),
      formatter: (cell, row) => formatDateforleadinout(cell)
    },

    {
      dataField: "pdcDate",
      text: "PDC (Date)",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row) => {
        const today = moment();
        const pdcDate = moment(cell);

        // Check if PDC date is within the next 7 days
        // const isCloseToDate = pdcDate.diff(today, "days") <= 7 && pdcDate.diff(today, "days") >= 0;
        const isTodayOrPastDate = pdcDate.isBefore(today) || pdcDate.isSame(today);

        return (
          <span>
            {moment(cell).format("YYYY-MM-DD")}{" "}
            {isTodayOrPastDate && (
              <FaExclamationTriangle style={{ color: "red", animation: "blink 1s infinite" }} />
            )}
          </span>
        );
      },
    },

    {
      dataField: "dateDifference",
      text: "Days Difference",
      formatter: (cell, row) => {
        const createdAt = moment(row.createdAt);
        const pdcDate = moment(row.pdcDate);
    
        // Calculate the difference in days
        const diffInDays = pdcDate.diff(createdAt, "days");
    
        // Return the difference
        return <span>{diffInDays} days</span>;
      }
    }
,    

    {
      dataField: "createdBy",
      text: "Recruitment Manager",
      filter: selectFilter({
        options: allRecruiters.length > 0 ? allRecruiters.map(emp => ({
          value: emp.empId,
          label: `${emp.fname} ${emp.lname}`,
        })) : [],
        placeholder: 'Select or Search by Name',
      }),

      formatter: (cell, row) => {
        const itemObj = allRecruiters.find(emp => emp.empId === row.createdBy);

        return (
          <div className="d-flex">
            {itemObj ? (
              <p>{`${itemObj.fname} ${itemObj.lname}`}</p>
            ) : (
              <p>Loading...</p> // Consider showing "Data not available" if necessary
            )}
          </div>
        );
      },
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">
          <MdEdit
            size={25}
            color="black"
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => handleEdit(row)}
          />

          <FaWhatsapp
            onClick={() => openwhatsApp(row?.primaryPOCMobile)}
            size={20}
            color="limegreen"
          />

          {data?.role?.roleName === "SuperAdmin" && (
            <MdOutlineDelete
              size={25}
              color="red"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.vacancyId)}
            />
          )}





        </div>
      ),
    },
  ];


  // Custom CSS for blinking effect
  const styles = `
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`;
  // Function to handle row selection
  const handleRowSelect = (row, isSelected) => {

    setSelectedRows([...selectedRows, row])


  }; console.log(selectedRows)

  // Function to handle row selection for all rows
  const handleRowSelectAll = (isSelected, rows) => {
    // Update selectedRows state when all rows are selected or deselected
    console.log(rows)
    if (isSelected) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const openwhatsApp = async (mobile) => {
    try {
      let phone
      if (!mobile.startsWith("+91")) {
        phone = "+91" + mobile;
      }
      const whatsappUrl = `https://wa.me/${phone}`;
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };





  useEffect(() => {
    const fetchData = async () => {
      await getAllCompany();
      await getAllVacancy();
      await getAllRecruiters();
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Or a spinner component
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 card border-0">
            <p className="fs-3 fw-normal typed-text">Manage Vacancy</p>
          </div>

          <div className="card col-md-4 border-0">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-warning btn-sm ms-1"
                onClick={() => handleShow(true)}
              >
                <FaAddressCard color={"black"} size={20} /> Add Vacancy
              </button>
            </div>
          </div>



          <div className="card col-md-12 border-0">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src={loadinggif} alt="Loading" />
              </div>
            ) : (
              <ToolkitProvider
                keyField="companyId"
                data={allVacancy}
                columns={columns}
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
              >
                {
                  props => (
                    <div>
                      <ExportCSVButton className="btn btn-success btn-sm mb-2 float-end" {...(props.csvProps || {})} filename="MantrasERP_Record.csv">< SiMicrosoftexcel /> Export Record</ExportCSVButton>

                      <div className="table-responsive cus-table">
                        <style>{styles}</style> {/* Inject the blinking effect CSS */}
                        <BootstrapTable
                          {...props.baseProps}
                          selectedRows={selectedRows}
                          filter={filterFactory()}
                          bootstrap4
                          keyField="companyId"
                          data={allVacancy}
                          columns={columns}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory({ showTotal: true, })}
                          selectRow={{
                            mode: 'checkbox',
                            clickToSelect: true,
                            onSelect: handleRowSelect,
                            onSelectAll: handleRowSelectAll,
                          }}
                          filterPosition="top"
                        />
                      </div>
                    </div>
                  )
                }
              </ToolkitProvider>
            )}
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>

            <p>Edit Vacancy</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>

          <div className="row">
            <div className="col-md-6">
              <p className="mb-0 fs-6">Select Company</p>
              <select
                className="form-control py-0"
                value={vacancy.companyId}
                onChange={(e) => {
                  const selectedCompanyId = e.target.value;
                  const selectedCompany = allCompany.find(
                    (item) => item.companyId === selectedCompanyId
                  );

                  console.log(selectedCompany, "hdcbdhdbhdbhdhbhcb")

                  handleVacancyChange(selectedCompanyId, "companyId");
                  handleVacancyChange(selectedCompany?.companyName, "companyName");
                  handleVacancyChange(selectedCompany?.primaryPOCMobile || "", "primaryPOCMobile");
                }}
              >
                <option value="">Select</option>
                {allCompany.length > 0 &&
                  allCompany.map((item) => (
                    <option key={item.companyId} value={item.companyId}>
                      {item.companyName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-md-6">
              <p className="mb-0 fs-6">POC Number</p>
              <input
                className="form-control py-0"
                disabled
                value={vacancy.primaryPOCMobile || ""}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "primaryPOCMobile")
                }
              />
            </div>


            <div className="col-md-4">
              <p className="mb-0 fs-6">Vacancy Status</p>

              <select
                className="form-control py-0"
                value={vacancy.vacancyStatus}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "vacancyStatus")
                }
              >
                <option value="">Select</option>
                <option value="Open">Open</option>
                <option value="Hold By Client">Hold By Client</option>
                <option value="Filled">Filled</option>
                <option value="Hold By EM">Hold By EM</option>
                <option value="Closed">Closed</option>
              </select>
            </div>

            <div className="col-md-4">
              <p className="mb-0 fs-6">Job Profile</p>
              <select
                placeholder="Job Profile"
                className="form-control py-0 "
                value={vacancy.jobProfile}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "jobProfile")
                }
              >
                <option value="no selection">Select Profile</option>
                <option value="Account Executive">Account Executive</option>
                <option value="Account Manager">Account Manager</option>
                <option value="Admission Coordinator">
                  Admission Coordinator
                </option>
                <option value="Admin Executive">Admin Executive</option>
                <option value="Admin Manager">Admin Manager</option>
                <option value="Area Sales Manager (ASM)">
                  Area Sales Manager (ASM)
                </option>
                <option value="Article Assistant">Article Assistant</option>
                <option value="Assistant Manager">Assistant Manager</option>
                <option value="Auditor">Auditor</option>
                <option value="Autocad Engineer">Autocad Engineer</option>
                <option value="Backend or Back Office Executive or Assistant Executive">
                  Backend or Back Office Executive or Assistant Executive
                </option>
                <option value="Banquet Manager">Banquet Manager</option>
                <option value="Billing Executives">
                  Billing Executives
                </option>
                <option value="Block Officer">Block Officer</option>
                <option value="Boiler Operator">Boiler Operator</option>
                <option value="Brand Manager">Brand Manager</option>
                <option value="Business Analyst">Business Analyst</option>
                <option value="Business Development Executive (BDE)">
                  Business Development Executive (BDE)
                </option>
                <option value="Business Development Manager (BDM)">
                  Business Development Manager (BDM)
                </option>
                <option value="Cashier">Cashier</option>
                <option value="Civil Engineer">Civil Engineer</option>
                <option value="Clinical Assistants">
                  Clinical Assistants
                </option>
                <option value="Collection Executives">
                  Collection Executives
                </option>
                <option value="Commodity Dealer">Commodity Dealer</option>
                <option value="Company Secretary">Company Secretary</option>
                <option value="Computer Operator">Computer Operator</option>
                <option value="Content Writer">Content Writer</option>
                <option value="Counsellor">Counsellor</option>
                <option value="Credit Manager">Credit Manager</option>
                <option value="Customer Care Executive (CCE)">
                  Customer Care Executive (CCE)
                </option>
                <option value="Customer Relationship Officer">
                  Customer Relationship Officer
                </option>
                <option value="Data Analyst">Data Analyst</option>
                <option value="Deputy Manager">Deputy Manager</option>
                <option value="Digital Marketer">Digital Marketer</option>
                <option value="Electrical Engineer">
                  Electrical Engineer
                </option>
                <option value="Electrician">Electrician</option>
                <option value="Equity Dealer">Equity Dealer</option>
                <option value="Export Manager">Export Manager</option>
                <option value="Fashion Designer">Fashion Designer</option>
                <option value="Finance Executive">Finance Executive</option>
                <option value="Finance Officer">Finance Officer</option>
                <option value="Financial Advisor">Financial Advisor</option>
                <option value="Food & Beverages Executive">
                  Food & Beverages Executive
                </option>
                <option value="Food & Beverages Manager">
                  Food & Beverages Manager
                </option>
                <option value="Front Office Executive">
                  Front Office Executive
                </option>
                <option value="GM/Unit Head">GM/Unit Head</option>
                <option value="Graphics Designer">Graphics Designer</option>
                <option value="Hotel Captain">Hotel Captain</option>
                <option value="Hotel Manager">Hotel Manager</option>
                <option value="HR Executive">HR Executive</option>
                <option value="HR Manager">HR Manager</option>
                <option value="Inventory Manager">Inventory Manager</option>
                <option value="IT Security Coordinator">
                  IT Security Coordinator
                </option>
                <option value="IT Trainer">IT Trainer</option>
                <option value="ITI Fitter">ITI Fitter</option>
                <option value="Lab Technician">Lab Technician</option>
                <option value="Law Officer">Law Officer</option>
                <option value="Liasoning Manager">Liasoning Manager</option>
                <option value="Logistic Manager">Logistic Manager</option>
                <option value="Machine Operator">Machine Operator</option>
                <option value="Maintenance Engineer">
                  Maintenance Engineer
                </option>
                <option value="Mechanical Engineer">
                  Mechanical Engineer
                </option>
                <option value="Medical Representative">
                  Medical Representative
                </option>
                <option value="Merchandiser">Merchandiser</option>
                <option value="MIS Executive">MIS Executive</option>
                <option value="Networking Manager">
                  Networking Manager
                </option>
                <option value="Nurse">Nurse</option>
                <option value="Operation Manager">Operation Manager</option>
                <option value="Pathologist">Pathologist</option>
                <option value="Pharmacist">Pharmacist</option>
                <option value="Placement Officer">Placement Officer</option>
                <option value="Plant Planning Manager">
                  Plant Planning Manager
                </option>
                <option value="Plant Supervisor">Plant Supervisor</option>
                <option value="PRO">PRO</option>
                <option value="Production Manager">
                  Production Manager
                </option>
                <option value="Project Manager or Team Leader">
                  Project Manager or Team Leader
                </option>
                <option value="Purchase Executive">
                  Purchase Executive
                </option>
                <option value="Quality Analyst">Quality Analyst</option>
                <option value="Quality Control or Process Control">
                  Quality Control or Process Control
                </option>
                <option value="Receptionist">Receptionist</option>
                <option value="Regional Manager">Regional Manager</option>
                <option value="Restaurant Manager">
                  Restaurant Manager
                </option>
                <option value="Safety Officer">Safety Officer</option>
                <option value="Sales Manager or Marketing Manager">
                  Sales Manager or Marketing Manager
                </option>
                <option value="Sales or Marketing Executive">
                  Sales or Marketing Executive
                </option>
                <option value="SAP or ERP Developer">
                  SAP or ERP Developer
                </option>
                <option value="SEO Executive or Manager">
                  SEO Executive or Manager
                </option>
                <option value="Service Delivery Manager">
                  Service Delivery Manager
                </option>
                <option value="Site Engineer">Site Engineer</option>
                <option value="Social Media Marketing">
                  Social Media Marketing
                </option>
                <option value="Soft Skill Trainer">
                  Soft Skill Trainer
                </option>
                <option value="Software Developer">
                  Software Developer
                </option>
                <option value="Store Manager">Store Manager</option>
                <option value="Supervisor">Supervisor</option>
                <option value="T&D Manager">T&D Manager</option>
                <option value="Teacher or Lecturer or Trainer">
                  Teacher or Lecturer or Trainer
                </option>
                <option value="Team Leader">Team Leader</option>
                <option value="Technical Sales">Technical Sales</option>
                <option value="Telecaller">Telecaller</option>
                <option value="Video Editor">Video Editor</option>
                <option value="Vice President">Vice President</option>
                <option value="Waiter or Steward">Waiter or Steward</option>
                <option value="Warehouse Manager">Warehouse Manager</option>
                <option value="Web Designer">Web Designer</option>
                <option value="Web Developer">Web Developer</option>
                <option value="Zonal Manager">Zonal Manager</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-md-4">
              <p className="mb-0 fs-6">Sector</p>
              <select
                placeholder="Sector"
                className="form-control py-0 "
                value={vacancy.sector}
                onChange={(e) => handleVacancyChange(e.target.value, "sector")}
              >
                <option value="no selection">Select Sector</option>
                <option value="Accounts or CA or CS or Taxation">
                  Accounts or CA or CS or Taxation
                </option>
                <option value="Adhesives">Adhesives</option>
                <option value="Agency or Consulting">
                  Agency or Consulting
                </option>
                <option value="Agriculture">Agriculture</option>
                <option value="Any Official">Any Official</option>
                <option value="Automobile">Automobile</option>
                <option value="Aviation">Aviation</option>
                <option value="Banking">Banking</option>
                <option value="Biotech or R&D or Scientist">
                  Biotech or R&D or Scientist
                </option>
                <option value="BPO">BPO</option>
                <option value="Chemical">Chemical</option>
                <option value="Distribution & Supply Chain">
                  Distribution & Supply Chain
                </option>
                <option value="Education">Education</option>
                <option value="Energy">Energy</option>
                <option value="Engineering Projects">
                  Engineering Projects
                </option>
                <option value="Entertainment">Entertainment</option>
                <option value="Export or Import">Export or Import</option>
                <option value="Finance or Micro Finance">
                  Finance or Micro Finance
                </option>
                <option value="Financial Services">
                  Financial Services
                </option>
                <option value="FMCG">FMCG</option>
                <option value="Food">Food</option>
                <option value="Glass">Glass</option>
                <option value="Govt. Sector">Govt. Sector</option>
                <option value="Home Furnishing">Home Furnishing</option>
                <option value="Hospital or Healthcare">
                  Hospital or Healthcare
                </option>
                <option value="Hotels or Restaurants">
                  Hotels or Restaurants
                </option>
                <option value="Immigration">Immigration</option>
                <option value="Insurance">Insurance</option>
                <option value="IT Hardware">IT Hardware</option>
                <option value="IT Software">IT Software</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Media and Advertising">
                  Media and Advertising
                </option>
                <option value="Oil & Lubricants">Oil & Lubricants</option>
                <option value="Online Marketing or Digital Marketing">
                  Online Marketing or Digital Marketing
                </option>
                <option value="Paint">Paint</option>
                <option value="Pharmaceutical">Pharmaceutical</option>
                <option value="Plastic or Pet">Plastic or Pet</option>
                <option value="Plywood & Laminates">
                  Plywood & Laminates
                </option>
                <option value="Publishing & Printing">
                  Publishing & Printing
                </option>
                <option value="Real Estate">Real Estate</option>
                <option value="Retail">Retail</option>
                <option value="Technology">Technology</option>
                <option value="Telecom">Telecom</option>
                <option value="Transport or Logistics">
                  Transport or Logistics
                </option>
                <option value="Transport or Tour & Travel">
                  Transport or Tour & Travel
                </option>
                <option value="Warehouse or Inventory">
                  Warehouse or Inventory
                </option>

                <option value="other">Other</option>
              </select>
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Location</p>
              <input
                placeholder="Location"
                className="form-control py-0 "
                type="text"
                value={vacancy.location}
                onChange={(e) => handleVacancyChange(e.target.value, "location")}
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">No Of Vacancy</p>
              <input
                placeholder="No Of Vacancy"
                className="form-control py-0 "
                type="number"
                value={vacancy.noOfVacancy}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "noOfVacancy")
                }
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Salary Range(Min)</p>
              <input
                placeholder="Minimum Salary Range"
                className="form-control py-0 "
                type="text"
                min={0}
                value={vacancy.salaryRangeMin}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "salaryRangeMin")
                }
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Salary Range(Max)</p>
              <input
                placeholder="Maximum Salary Range"
                className="form-control py-0 "
                type="text"
                min={0}
                value={vacancy.salaryRangeMax}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "salaryRangeMax")
                }
              />
            </div>
            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Required Experience</p>
              <select
                className="form-control py-0 "
                value={vacancy.experience}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "experience")
                }
              >
                <option value="">Select Required Experience</option>
                <option value="0">0</option>
                <option value="0.6">0.6</option>
                {Array.from({ length: 99 }, (_, i) => i + 1).map(
                  (option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  )
                )}
              </select>
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Gender Preference</p>

              <select
                className="form-control py-0"
                value={vacancy.genderpref}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "genderpref")
                }
              >
                <option value="">Select Gender Preference</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Any">Any</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Job Discription</p>
              <input
                placeholder="Job Discription "
                className="form-control py-0 "
                type="text"
                value={vacancy.jobDiscription}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "jobDiscription")
                }
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">PDC (Date)</p>
              <input
                placeholder="Select PDC Date"
                className="form-control py-0 "
                type="date"
                value={vacancy.pdcDate}
                onChange={(e) => handleVacancyChange(e.target.value, "pdcDate")}
              />
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Terms & Conditions</p>

              <select
                className="form-control py-0"
                value={vacancy.tANDc}
                onChange={(e) => handleVacancyChange(e.target.value, "tANDc")}
              >
                <option value="">Select</option>
                <option value="Any">Any</option>
                <option value="Only H">Only H</option>
                <option value="Only M">Only M</option>
              </select>
            </div>

            <div className="col-md-4 mt-2">
              <p className="mb-0 fs-6">Date of Creation</p>
              <input
                placeholder="Date of Creation"
                className="form-control py-0 "
                type="date"
                value={vacancy.dateOfCreation}
                onChange={(e) =>
                  handleVacancyChange(e.target.value, "dateOfCreation")
                }
              />
            </div>

            {data?.role?.roleName === "SuperAdmin" ? (
              <>
                <div className="col-md-4 mt-2">
                  <p className="mb-0 fs-6">Recruitment Manager </p>
                  <select
                    className="form-control"
                    value={vacancy.createdBy || ""}
                    onChange={(e) =>
                      setVacancy((prevState) => ({
                        ...prevState,
                        createdBy: e.target.value,
                      }))
                    }
                    required
                  >
                    <option value="Not assigned">
                      Select Recruitment Manager
                    </option>
                    {allRecruiters.map((item, index) => (
                      <option key={index} value={item.empId}>
                        {`${item.fname} ${item.mname} ${item.lname}`}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            ) : null}
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} size="sm">
            Close
          </Button>
          {isEdit ?

            <Button variant="primary" onClick={handleUpdateVacancy} size="sm">
              Update Vacancy
            </Button>
            :

            <Button variant="primary" onClick={handleVacancySubmit} size="sm">
              Add Vacancy
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VacancyManagement;
