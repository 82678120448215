import React from 'react'

const TextInput = (props) => {
    const { title, value, name, placeholder, handleChange , custStyle } = props
    return (
        <>
            <label htmlFor="residentaladdress" className='p-0 m-0 cus-label'>{title}</label>
            <input
                type="text"
                className={`form-control cus-textInput-style rounded-0 p-0 m-0 bg-white ${custStyle}`}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                {...props}
            />
        </>
    )
}
export default TextInput