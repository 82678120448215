import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BiSolidError } from "react-icons/bi";
import './ErrorPopup.css'

const ErrorPopup = ({ errors }) => {
  const [show, setShow] = useState(false);

  // Open the modal when errors are received
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setShow(true);
    }
  }, [errors]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title" className='text-danger'>
            <BiSolidError className='blinking-icon'/>Error
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {Object.values(errors).map((error, index) => (
              <li className='text-danger' key={index}>{error}</li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ErrorPopup;
