import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ChartComponent = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data.labels,
        datasets: [{
          label: 'Lead Data',
          data: data.values,
          backgroundColor: 'rgba(59, 113, 202, 1)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 0.2
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    return () => myChart.destroy();
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default ChartComponent;
