import React, { useState } from "react";

const CoursesStructure = () => {
  const [formData, setFormData] = useState({
    courseId: "",
    courseName: "",
    timeDuration: "",
    feesStructure: "",
    syllabus: null,
    paymentInstallment: "",
  });

  const [tableData, setTableData] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, syllabus: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTableData([...tableData, formData]);
    setFormData({
      courseId: "",
      courseName: "",
      timeDuration: "",
      feesStructure: "",
      syllabus: null,
      paymentInstallment: "",
    });
  };

  const handleEdit = (index) => {
    setFormData(tableData[index]);
  };

  const handleSave = (index) => {
    // Add your logic to save edited data
    // For simplicity, we'll just replace the data in the array
    const updatedTableData = [...tableData];
    updatedTableData[index] = formData;
    setTableData(updatedTableData);
    setFormData({
      courseId: "",
      courseName: "",
      timeDuration: "",
      feesStructure: "",
      syllabus: null,
      paymentInstallment: "",
    });
  };

  const handleDelete = (index) => {
    // Add your logic to delete data
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  return (
    <>
      <div class="card bg-light ml-3 mr-3">
        <div class="card-body">
            <h5><b>Courses Structure</b></h5>
          <form onSubmit={handleSubmit}>
            {/* Course ID */}
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="courseId">Course ID</label>
                <input
                  type="text"
                  class="form-control"
                  id="courseId"
                  name="courseId"
                  value={formData.courseId}
                  onChange={handleInputChange}
                />
              </div>

              {/* Course Name */}

              <div class="form-group col-md-4">
                <label for="courseName">Course Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="courseName"
                  name="courseName"
                  value={formData.courseName}
                  onChange={handleInputChange}
                />
              </div>

              {/* Time Duration */}

              <div class="form-group col-md-4">
                <label for="timeDuration">Time Duration</label>
                <input
                  type="text"
                  class="form-control"
                  id="timeDuration"
                  name="timeDuration"
                  value={formData.timeDuration}
                  onChange={handleInputChange}
                />
              </div>

              {/* Fees Structure */}

              <div class="form-group col-md-4">
                <label for="feesStructure">Fees Structure</label>
                <select
                  class="form-control"
                  id="feesStructure"
                  name="feesStructure"
                  value={formData.feesStructure}
                  onChange={handleInputChange}
                >
                  {/* Add dynamic options for fees structure */}
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  {/* Add more options as needed */}
                </select>
              </div>

              {/* Syllabus */}

              <div class="form-group col-md-4">
                <label for="syllabus">Syllabus</label>
                <input
                  type="file"
                  class="form-control-file"
                  id="syllabusFile"
                  label="Choose file"
                  onChange={handleFileChange}
                />
              </div>

              {/* Payment Installment */}

              <div class="form-group col-md-4">
                <label for="paymentInstallment">Payment Installment</label>
                <select
                  class="form-control"
                  id="paymentInstallment"
                  name="paymentInstallment"
                  value={formData.paymentInstallment}
                  onChange={handleInputChange}
                >
                  {/* Add dynamic options for payment installment */}
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  {/* Add more options as needed */}
                </select>
              </div>
            </div>

            {/* Submit Button */}
            <button type="submit" class="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>

      <div class="card bg-light ml-3 mr-3">
        <div class="card-body">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>Course ID</th>
                <th>Course Name</th>
                <th>Time Duration</th>
                <th>Fees Structure</th>
                <th>Syllabus</th>
                <th>Payment Installment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, index) => (
                <tr key={index}>
                  <td>{data.courseId}</td>
                  <td>{data.courseName}</td>
                  <td>{data.timeDuration}</td>
                  <td>{data.feesStructure}</td>
                  <td>{data.syllabus ? data.syllabus.name : "N/A"}</td>
                  <td>{data.paymentInstallment}</td>
                  <td>
                    <button
                      class="btn btn-info"
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </button>
                    <button
                      class="btn btn-success"
                      onClick={() => handleSave(index)}
                    >
                      Save
                    </button>
                    <button
                      class="btn btn-danger"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CoursesStructure;
