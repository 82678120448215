import React, { useState, useEffect } from "react";
import edMantra from "../../services/edMantra";
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import moment from 'moment'; // Import moment library for date formatting

import ErrorPopup from '../Errors/ErrorPopup'; // Import ErrorPopup component

const EDAddLead = ({ handleClose }) => {
  const [assignee, setAssignee] = useState([]);
  const [lead, setLead] = useState({ telecaller: "select", source: "" });
  const [errors, setErrors] = useState({});
  const [selectedSource, setSelectedSource] = useState('');
  const [personName, setpersonName] = useState('');
  const [personContact, setpersonContact] = useState('');
  // Handle nextfollow separately
  const [selectedDate, setSelectedDate] = useState(null);
  const getAllEmployees = async () => {
    try {
      const allEmployees = await edMantra.getAllEmployees();
      setAssignee(allEmployees.data.data);
    } catch (err) {
      console.error(err, "Error fetching data");
    }
  };





  const handleLead = async () => {
    try {
      const validationErrors = validateForm(lead);
      if (Object.keys(validationErrors).length === 0) {
        // Form is valid, proceed with submitting data
        const leadData = { ...lead, counselorComment: "Enter comment", remark: "Enter remark", agentName: "NA", rating: "00" };
        const result = await edMantra.addLead(leadData);

        if (result.status === 201) {
          // Successful response
          toast(result?.data?.message);
          setLead({});
          handleClose(false);
        } else {
          // Error response
          console.log("Response status code:", result.status);
          let errorMessage = "Please check";
          if (result?.data?.error) {
            errorMessage = result.data.error;
          }
          toast.error(errorMessage);
        }
      } else {
        // Set validation errors
        setErrors(validationErrors);
        Object.keys(validationErrors).forEach((key) => {
          toast.error(validationErrors[key]);
        });
      }
    } catch (error) {
      // Handle errors that are not related to the server response status
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        console.error("An error occurred:", error);
        toast.error('An error occurred while submitting the form. Please try again.');
      }
    }
  }


  const validateForm = (data) => {
    let errors = {};
    if (!/^[a-zA-Z\s]+$/.test(data.fullName)) {
      errors.fullName = "Only Characte is allowed in Full Name";
    }



    if (!/^\d{10}$/.test(data.mobile)) {
      errors.mobile = 'Mobile number must be exactly 10 digits long';
    }

    if (!/^\d{10}$/.test(data.altMobile)) {
      errors.altMobile = 'Alternative Phone number must be exactly 10 digits long';
    }

    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!/^[a-zA-Z\s]+$/.test(data.state)) {
      errors.state = "Only Characte is allowed in State/Terriotry";
    }
    if (!/^[a-zA-Z\s]+$/.test(data.city)) {
      errors.city = "Only Characte is allowed in City/Town";
    }

    if (!data.query) {
      errors.query = "Please select query subject";
    }

    if (!data.status) {
      errors.status = "Please put this lead under a status";
    }

    // if (!/^\d{1,2}(\.\d{1,2})?$/.test(data.rating)) {
    //   errors.rating = "Rating should be a decimal number up to 10.00";
    // }

    if (data.nextfollow === null) {
      errors.nextfollow = "You cannot leave the next follow-up date blank";
    }

    return errors;
  };

  //------------------validations-------------------------------
  const handleOnchange = (e) => {
    const { name, value } = e.target;
  
    setLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  
    if (name === 'lead.source') {
      setSelectedSource(value);
    }
  };
  

  useEffect(() => {
    getAllEmployees()
  }, []);

  useEffect(() => {
    console.log('Selected Source:', selectedSource);
  }, [selectedSource]);
  
  useEffect(() => {
    console.log('Lead State:', lead);
  }, [lead]);
  

  return (
    <div className="mt-lg-5">
      {/* Add ErrorPopup component here */}
      {Object.keys(errors).length > 0 && <ErrorPopup errors={errors} />}

      <div className="row">
        <div className="col-md-12">
          <h5>Add Lead</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Full Name:</label>
            <input
              type="text"
              className="form-control"
              value={lead.fullName}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  fullName: e.target.value,
                }))
              }
            />

          </div>
        </div>


        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Mobile Number:</label>
            <input
              type="text"
              className="form-control"
              value={lead.mobile}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  mobile: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Alternate Mobile Number:</label>
            <input
              type="text"
              className="form-control"
              value={lead.altMobile}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  altMobile: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              className="form-control"
              value={lead.email}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>State:</label>
            <input
              type="text"
              className="form-control"
              value={lead.state}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  state: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>City:</label>
            <input
              type="text"
              className="form-control"
              value={lead.city}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  city: e.target.value,
                }))
              }
            />
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Education:</label>
            <input
              type="text"
              className="form-control"
              value={lead.pincode}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  pincode: e.target.value,
                }))
              }
            />
          </div>
        </div>

        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Lead Query:</label>
            <select
              className="form-control"
              value={lead.query}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  query: e.target.value,
                }))
              }
            >
              <option value="">
                <b>Select Lead Query</b>
              </option>
              <option value="Digital Marketing (Full Course)">
                Digital Marketing (Full Course)
              </option>
              <option value="Digital Marketing (2.5 Months)">
                Digital Marketing (2.5 Months)
              </option>
              <option value="Digital Marketing (45 Days)">
                Digital Marketing (45 Days)
              </option>
              <option value="Website Designing (Wordpress)">
                Website Designing (Wordpress)
              </option>
              <option value="Advanced Graphic Designing">
                Advanced Graphic Designing
              </option>
              <option value="Coral Draw">Coral Draw</option>
              <option value="Illustrator">Illustrator</option>
              <option value="Photoshop">Photoshop</option>
              <option value="Motion Graphics">Motion Graphics</option>
              <option value="Video Editing">Video Editing</option>
              <option value="Human Resource Management">
                Human Resource Management
              </option>
              <option value="Payroll Management">Payroll Management</option>
              <option value="Talent Acquistion">Talent Acquistion</option>
              <option value="MIS & Advance Excel">MIS & Advance Excel</option>
              <option value="MIS+Tally">MIS+Tally</option>
              <option value="Tally">Tally</option>
              <option value="PowerBI">PowerBI</option>
              <option value="Data Science">Data Science</option>
              <option value="ADCA">ADCA</option>
              <option value="Certification In Computer Application (CCA)">
                Certification In Computer Application (CCA)
              </option>
              <option value="Java">Java</option>
              <option value="Advance Python">Advance Python</option>
              <option value="Database MYSQL">Database MYSQL</option>
              <option value="Flutter">Flutter</option>
              <option value="Salesforce CRM">Salesforce CRM</option>
              <option value="C Language">C Language</option>
              <option value="C++ Language">C++ Language</option>
              <option value="Hindi & English Typing">
                Hindi & English Typing
              </option>
              <option value="English Speaking">English Speaking</option>
              <option value="Artificial Intelligence(AI)">
                Artificial Intelligence(AI)
              </option>
              <option value="CCC">CCC</option>
              <option value="A Level">A Level</option>
              <option value="O Level">O Level</option>
              <option value="Personality Development">
                Personality Development
              </option>
              <option value="Employability Skills">Employability Skills</option>
              <option value="Combo Course">
                Combo Course
              </option>
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="Status">Lead Status:</label>
            <select
              id="status"
              className="form-control"
              value={lead.status}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  status: e.target.value,
                }))
              }
            >
              <option value="">Select Lead Status </option>
              <option value="New Lead">⚪️New Lead</option>
              <option value="Hot Lead"> 🔴Hot Lead</option>
              <option value="Warm Lead">🟡Warm Lead </option>
              <option value="Cold Lead"> 🟤Cold Lead</option>
              <option value="Dead Lead"> ⚫Dead Lead</option>
              <option value="Visited">🟠Visited</option>
              <option value="Registration"> 🔵Registration</option>
              <option value="Admission"> 🟢Admission</option>
              <option value="Close"> 🟣Close</option>
              <option value="For Job"> 🔵For Job</option>
              <option value="Agency Work">⭕Agency Work</option>
            </select>
          </div>
        </div>

        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Assign To:</label>
            <select

              className="form-control"
              value={lead.telecaller}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  telecaller: e.target.value,
                }))
              }
            ><option value="Not assigned">Select Assignee</option>
              {assignee &&
                assignee?.map((item) => {
                  return (
                    <>
                      <option value={item?.empId}>
                        {item?.fname + " " + item?.mname + " " + item?.lname}
                      </option>
                    </>
                  );
                })}
            </select>
          </div>
        </div>


        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Next Follow Up date:</label>
            <DatePicker
              className="form-control"
              selected={lead.nextfollow}
              onChange={(date) => {
                // Check if date is not null
                if (date) {
                  // Set time part of the date to 11:00 AM IST
                  date.setHours(11);
                  date.setMinutes(0);
                  date.setSeconds(0);
                  date.setMilliseconds(0);
                }

                setLead((prevState) => ({
                  ...prevState,
                  nextfollow: date,
                }));
              }}
              placeholderText="Select Next Follow Up date"
              dateFormat="yyyy-MM-dd"
              required={true}
            />




          </div>
        </div>



        <div className="col-md-3 mb-3">
          <div className="form-group">
            <label>Lead Source</label>
            <select
              className="form-control"
              value={lead.source}
              onChange={(e) =>
                setLead((prevState) => ({
                  ...prevState,
                  source: e.target.value,
                }))
              }
            >
              <option value="">Select a source</option>
              <option value="Website">Website</option>
              <option value="Facebook Ad">Facebook Ad</option>
              <option value="Instagram Ad">Instagram Ad</option>
              <option value="Google Ad">Google Ad</option>
              <option value="Just Dial">Just Dial</option>
              <option value="Walk-In">Walk-In</option>
              <option value="Incoming Call">Incoming Call</option>
              <option value="Reference">Reference</option>
              <option value="Alumni">Alumni</option>
              <option value="Field Data">Field Data</option>
              <option value="Seminar">Seminar</option>
              <option value="Webinar">Webinar</option>
              <option value="Schools">Schools</option>
              <option value="Coaching's">Coaching's</option>
              <option value="Event">Event </option>
              <option value="Activities">Activities</option>
              <option value="Admission Centre">Admission Centre</option>
              <option value="Franchise">Franchise</option>
              <option value="Self-Generated">Self-Generated</option>
              <option value="Employment Mantras">Employment Mantras</option>
              <option value="Purchased Data">Purchased Data</option>
              <option value="WhatsApp">WhatsApp</option>
              <option value="WhatsApp Status">WhatsApp Status</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="GMB">GMB</option>
              <option value="WhatsApp Group">WhatsApp Group</option>
              <option value="Other">Other</option>
              <option value="Google">Google</option>
            </select>
          </div>
        </div>

      </div>

      {lead.source === "Reference" && (
        <div className="row">
          <div className="col-md-3 mb-3">
            <div className="form-group">
              <label>Person Name</label>
              <input
                type="text"
                className="form-control"
                value={lead.personName}
                placeholder="Referred By"
                onChange={(e) =>
                  setLead((prevState) => ({
                    ...prevState,
                    personName: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <div className="col-md-3 mb-3">
            <div className="form-group">
              <label>Person Contact</label>
              <input
                type="text"
                className="form-control"
                value={lead.personContact}
                placeholder="Contact Number"
                onChange={(e) =>
                  setLead((prevState) => ({
                    ...prevState,
                    personContact: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
      )}





      <div className="row mx-1">
        <button
          className="btn btn-primary btn-block"
          type="submit"
          onClick={() => handleLead()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EDAddLead;
