import React, { useRef } from 'react'
import AppRoutes from '../AppRoutes/AppRoutes'
import Header from '../Header/Header'

const PageContent = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  return (
    <div className='PageContent' style={{ height: `${windowSize.current[1]}px` }} >
      <Header />
      <AppRoutes />
    </div>
  )
}

export default PageContent