import React, { useState } from "react";
import { Card, Table, Form, Button } from "react-bootstrap";

const TimeTracking = () => {
  const [tableData, setTableData] = useState([
    {
      id: 1,
      name: "John Doe",
      employeeId: "EMP001",
      loginTime: "09:00 AM",
      logoutTime: "05:00 PM",
      date: "12/09/2023",
    },
    {
      id: 2,
      name: "Jane Doe",
      employeeId: "EMP002",
      loginTime: "09:30 AM",
      logoutTime: "06:00 PM",
      date: "13/12/2023",
    },
    // Add more rows as needed
  ]);

  return (
    <Card>
    <Card.Body>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>S.NO.</th>
            <th>Employee Name</th>
            <th>Employee ID</th>
            <th>Login Time</th>
            <th>Logout Time</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>
                <Form.Control
                  type="text"
                  value={row.name}
                  readOnly
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={row.employeeId}
                  readOnly
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={row.loginTime}
                  readOnly
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={row.logoutTime}
                  readOnly
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={row.date}
                  readOnly
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="6">
              <Button onClick={() => window.print()}>Print</Button>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Card.Body>
  </Card>
  );
};

export default TimeTracking;
