import React, { useEffect } from "react";
import "./App.css";
import Sidebar from "./Components/Sidebar/Sidebar";
import PageContent from "./Components/PageContent/PageContent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Pages/Login/Login";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { createSession } from "./Redux/reducers/SessionSlice";
import { jwtDecode } from "jwt-decode";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

const App = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("jwt");



  useEffect(() => {
    try {
      const decoded = jwtDecode(token);
      dispatch(createSession(decoded));
    } catch (error) {
      console.error("Error decoding token:", error.message);
    }
  }, [token]);

  return (
    <>
      <div className="App" >
        {token ? (
          <>
            <div className="SideMenuAndPageContent">
              <Sidebar />
              <PageContent />
            </div>
          </>
        ) : (
          <Login />
        )}
      </div>
      {/* <Footer /> */}
      <ToastContainer />
    </>
  );
};

export default App;
