import React, { useState, useEffect } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from "react-toastify";
import edMantra from "../../services/edMantra";
import { useSelector } from "react-redux";
import EDLeadForm from "../../Components/EDLeadForm/EDLeadForm";
import EDModal from "../../Components/EDModal/EDModal";
import { SiMicrosoftexcel } from "react-icons/si";
import leadsamplefile from "../../assets/files/leadfile.xlsx";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineDelete, MdEdit, MdEmail } from "react-icons/md";
import EDAddLead from "../../Components/EDLeadForm/EDAddLead";
import { FaFileDownload, FaFileExport, FaAddressCard, FaCloudUploadAlt, FaFilter } from "react-icons/fa";
import '../LeadManagementPages/Globalstyle.css'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import loadinggif from '../../assets/images/loading.gif'
import { Modal, Button } from 'react-bootstrap'; // Ensure this import is included
import * as XLSX from 'xlsx';
const Reporting = () => {
  const [leadData, setLeadData] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [summary, setSummary] = useState({});


  const fetchData = async () => {
    setIsLoading(true); // Set loading to true
    try {
      const response = await edMantra.reporting();
      setLeadData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set loading to false after the fetch
    }
  };

  const getAllEmployees = async () => {
    try {
      const allEmployees = await edMantra.getAllEmployees();
      setAllEmployees(allEmployees.data.data);
    } catch (err) {
      console.error(err, "Error fetching data");
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  useEffect(() => {
    fetchData();
  }, [refresh]);

  useEffect(() => {
    const calculateSummary = () => {
      const result = leadData.reduce((acc, item) => {
        if (item.personContact) {
          const key = `${item.personName || 'Unknown'} (${item.personContact})`;
          if (acc[key]) {
            acc[key].count += 1;
            acc[key].sum += item.value || 0; // Ensure value is handled correctly
          } else {
            acc[key] = {
              count: 1,
              sum: item.value || 0,
            };
          }
        }
        return acc;
      }, {});

      setSummary(result);
    };

    calculateSummary();
  }, [leadData]);


  const formatDateforleadinout = (dateString) => { //for lead createdAt and updatedAt
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };

  const formatDatefornextfollow = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };

  //---------------source filter option--------------------
  const leadSources = [
    { value: '', label: 'Select a source' }, // Default option
    { value: 'Website', label: 'Website' },
    { value: 'Facebook Ad', label: 'Facebook Ad' },
    { value: 'Instagram Ad', label: 'Instagram Ad' },
    { value: 'Google Ad', label: 'Google Ad' },
    { value: 'Just Dial', label: 'Just Dial' },
    { value: 'Walk-In', label: 'Walk-In' },
    { value: 'Incoming Call', label: 'Incoming Call' },
    { value: 'Reference', label: 'Reference' },
    { value: 'Alumni', label: 'Alumni' },
    { value: 'Field Data', label: 'Field Data' },
    { value: 'Seminar', label: 'Seminar' },
    { value: 'Webinar', label: 'Webinar' },
    { value: 'Schools', label: 'Schools' },
    { value: 'Coaching\'s', label: 'Coaching\'s' },
    { value: 'Event', label: 'Event' },
    { value: 'Activities', label: 'Activities' },
    { value: 'Admission Centre', label: 'Admission Centre' },
    { value: 'Franchise', label: 'Franchise' },
    { value: 'Self-Generated', label: 'Self-Generated' },
    { value: 'Employment Mantras', label: 'Employment Mantras' },
    { value: 'Purchased Data', label: 'Purchased Data' },
    { value: 'WhatsApp', label: 'WhatsApp' },
    { value: 'WhatsApp Status', label: 'WhatsApp Status' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'GMB', label: 'GMB' },
    { value: 'WhatsApp Group', label: 'WhatsApp Group' },
    { value: 'Other', label: 'Other' }
  ];
  const leadSourceFilter = selectFilter({
    options: leadSources
  });
  //-----------------------------------------------------------

  // -----------data table--------------------------------------------
  const { ExportCSVButton } = CSVExport;
  const formatRemark = (cell, row) => {
    return cell || ' '; // Return an empty string if cell value is undefined or null
  };

  const columns = [{
    dataField: 'SrNo',
    text: 'Sr. No',
    sort: 'true',
    filter: textFilter()
  }, {
    dataField: 'fullName',
    text: 'Full Name',
    filter: textFilter()
  }, {
    dataField: 'mobile',
    text: 'Contact No.',
    filter: textFilter()
  }, {
    dataField: 'query',
    text: 'Query',
    filter: textFilter()

  },
  {
    dataField: 'status',
    text: 'Status',
    filter: textFilter()

  }, {

    dataField: 'nextfollow',
    text: 'Next Follow Up Date',
    filter: dateFilter(),
    formatter: (cell, row) => formatDatefornextfollow(cell)

  }, {
    dataField: 'createdAt',
    text: 'Create Time',
    filter: dateFilter(),
    formatter: (cell, row) => formatDateforleadinout(cell)
  }, {
    dataField: 'updatedAt',
    text: 'Update Time',
    filter: dateFilter(),
    formatter: (cell, row) => formatDateforleadinout(cell)
  },
  {
    dataField: 'telecaller',
    text: 'Assign To',
    filter: selectFilter({
      options: allEmployees.map(emp => ({
        value: emp.empId,
        label: `${emp.fname} ${emp.lname}`,
      })),
      placeholder: 'Select or Search by Name'
    }),
    formatter: (cell, row) => {
      const telecaller = allEmployees?.find(emp => emp?.empId === row?.telecaller);
      if (telecaller) {
        return `${telecaller.fname} ${telecaller.lname}`;
      } else {
        return 'Not Assigned'; // Or any other placeholder text
      }
    }
  },
  {
    dataField: 'source',
    text: 'Lead Source',
    filter: leadSourceFilter,
  },

  {
    dataField: 'personName',
    text: 'Referred Person Name',
    filter: textFilter()
  },

  {
    dataField: 'personContact',
    text: 'Referred Person Contact',
    filter: textFilter()
  },

  {
    dataField: 'Remarks[0].remark',
    text: 'Latest Remark',
    formatter: formatRemark // Apply the formatRemark function as the formatter
  },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgcolor: 'red'
  };

  const handleDownloadExcel = () => {
   
    const data = Object.entries(summary).map(([contact, { count, sum }]) => {
      const [name, contactNo] = contact.split(' (');
      const cleanedContact = contactNo ? contactNo.replace(')', '') : '';
      return { 
        'Referred Person Name': name, 
        'Referred Person Contact': cleanedContact, 
        'Number of Leads': count, 
        'Sum': sum 
      };
    });

    // Create a new workbook and add a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Summary');

    // Write the workbook to a file and trigger download
    XLSX.writeFile(wb, 'Summary_of_Referred_Leads.xlsx');
  };

  //--------------data table--------------------------------------------
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <div className="card border-0">
            <p className="fs-3 fw-normal typed-text">Leads Reports</p>
          </div>
        </div>
      </div>

      {/* Button to open the modal */}
      <div className="row mb-3">
        <div className="col-lg-12">
          <Button className="btn-sm mb-2 float-end" variant="primary" onClick={() => setShowModal(true)}>
            Show Summary
          </Button>
        </div>
      </div>

      {/* Summary Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Summary of Referred Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Referred Person Name</th>
                <th>Referred Person Contact</th>
                <th>Number of Leads</th>
                <th>Sum</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(summary).map(([contact, { count, sum }]) => {
                // Extract name and contact from the key
                const [name, contactNo] = contact.split(' (');
                const cleanedContact = contactNo ? contactNo.replace(')', '') : '';
                return (
                  <tr key={contact}>
                    <td>{name}</td>
                    <td>{cleanedContact}</td>
                    <td>{count}</td>
                    <td>{sum}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
         
          <Button variant="success" onClick={handleDownloadExcel}>
            Download as Excel
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Data Table */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card border-0">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src={loadinggif} alt="Loading" />
              </div>
            ) : (
              <ToolkitProvider
                keyField="SrNo"
                data={leadData}
                columns={columns}
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
              >
                {
                  props => (
                    <div>
                      <ExportCSVButton className="btn btn-success btn-sm mb-2 float-end" {...(props.csvProps || {})} filename="MantrasERP_Record.csv">< SiMicrosoftexcel /> Export Record</ExportCSVButton>
                      <div className="table-responsive cus-table">
                        <BootstrapTable
                          {...props.baseProps}
                          filter={filterFactory()}
                          bootstrap4
                          keyField='SrNo'
                          data={leadData}
                          columns={columns}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory({ showTotal: true })}
                          filterPosition="top"
                        />
                      </div>
                    </div>
                  )
                }
              </ToolkitProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );

}

export default Reporting;
