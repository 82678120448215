import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LeaveManagement = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState("");
  const [leaveDefinition, setLeaveDefinition] = useState("");
  const [numberOfLeaves, setNumberOfLeaves] = useState("");
  const [sickLeaves, setSickLeaves] = useState("");
  const [paternityLeaves, setPaternityLeaves] = useState("");
  const [casualLeaves, setCasualLeaves] = useState("");
  const [tableData, setTableData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const handleFieldChange = (index, field, value) => {
    // Update the corresponding field in the edited row
    const updatedData = [...tableData];
    updatedData[index] = {
      ...updatedData[index],
      [field]: value,
    };
    setTableData(updatedData);
  };

  const handleSave = () => {
    // Add form data to tableData array
    const newData = {
      startDate,
      endDate,
      user: selectedUser,
      leaveDefinition,
      numberOfLeaves,
      sickLeaves,
      paternityLeaves,
      casualLeaves,
    };
    setTableData([...tableData, newData]);

    // Reset form fields
    setStartDate(new Date());
    setEndDate(new Date());
    setSelectedUser("");
    setLeaveDefinition("");
    setNumberOfLeaves("");
    setSickLeaves("");
    setPaternityLeaves("");
    setCasualLeaves("");
  };

  const handleSaveEdit = (index) => {
    // Save the edited row
    setEditIndex(null);
  };

  const handleCancelEdit = () => {
    // Cancel editing and reset the editIndex
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    // Start editing the selected row
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    // Delete the selected row
    const updatedData = [...tableData];
    updatedData.splice(index, 1);
    setTableData(updatedData);
  };

  return (
    <div className="container-fluid">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="mt-4">
          {/* Leave Management Form */}
          <div className="card bg-light">
            <div className="card-body">
              <h4 className="card-title">
                <b>Leave Management</b>
              </h4>
              <hr />
  
              <div className="row">
                {/* Start Date */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Start Date:</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control"
                  />
                </div>
  
                {/* End Date */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">End Date:</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="form-control"
                  />
                </div>
  
                {/* User Dropdown */}
                <div className="col-md-12 mb-3">
                  <label className="form-label">Select User:</label>
                  <select
                    className="form-select"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                  >
                    <option value="">Select User</option>
                    <option value="user1">User 1</option>
                    <option value="user2">User 2</option>
                    {/* Add more users as needed */}
                  </select>
                </div>
              </div>
  
              <div className="row">
                {/* Leave Definition */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Leave Definition:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={leaveDefinition}
                    onChange={(e) => setLeaveDefinition(e.target.value)}
                  />
                </div>
  
                {/* Number of Leaves */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Number of Leaves:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={numberOfLeaves}
                    onChange={(e) => setNumberOfLeaves(e.target.value)}
                  />
                </div>
  
                {/* Sick Leaves */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Sick Leaves:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={sickLeaves}
                    onChange={(e) => setSickLeaves(e.target.value)}
                  />
                </div>
  
                {/* Paternity Leaves */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Paternity Leaves:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={paternityLeaves}
                    onChange={(e) => setPaternityLeaves(e.target.value)}
                  />
                </div>
  
                {/* Casual Leaves */}
                <div className="col-md-6 mb-3">
                  <label className="form-label">Casual Leaves:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={casualLeaves}
                    onChange={(e) => setCasualLeaves(e.target.value)}
                  />
                </div>
              </div>
  
              {/* Save Button */}
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
  
          {/* Responsive Table */}
          <div className="mt-4">
            <table className="table table-bordered">
              {/* Table Header */}
              <thead>
                <tr>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>User</th>
                  <th>Leave Definition</th>
                  <th>Number of Leaves</th>
                  <th>Sick Leaves</th>
                  <th>Paternity Leaves</th>
                  <th>Casual Leaves</th>
                  <th>Action</th>
                </tr>
              </thead>
  
              {/* Table Body */}
              <tbody>
                {tableData.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {editIndex === index ? (
                        <DatePicker
                          selected={data.startDate}
                          onChange={(date) =>
                            handleFieldChange(index, "startDate", date)
                          }
                          className="form-control"
                        />
                      ) : (
                        data.startDate.toDateString()
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <DatePicker
                          selected={data.endDate}
                          onChange={(date) =>
                            handleFieldChange(index, "endDate", date)
                          }
                          className="form-control"
                        />
                      ) : (
                        data.endDate.toDateString()
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <select
                          className="form-select"
                          value={data.user}
                          onChange={(e) =>
                            handleFieldChange(index, "user", e.target.value)
                          }
                        >
                          <option value="">Select User</option>
                          <option value="user1">User 1</option>
                          <option value="user2">User 2</option>
                          {/* Add more users as needed */}
                        </select>
                      ) : (
                        data.user
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="form-control"
                          value={data.leaveDefinition}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "leaveDefinition",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        data.leaveDefinition
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="number"
                          className="form-control"
                          value={data.numberOfLeaves}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "numberOfLeaves",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        data.numberOfLeaves
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <input
                          type="number"
                          className="form-control"
                          value={data.sickLeaves}
                          onChange={(e) =>
                            handleFieldChange(index, "sickLeaves", e.target.value)
                          }
                        />
                      ) : (
                        data.sickLeaves
                      )}
                    </td>
                    <td>{data.paternityLeaves}</td>
                    <td>{data.casualLeaves}</td>
                    <td>
                      {editIndex === index ? (
                        <>
                          {/* Save Edited Row Button */}
                          <button
                            className="btn btn-success btn-sm me-2"
                            onClick={() => handleSaveEdit(index)}
                          >
                            Save
                          </button>
                          {/* Cancel Edit Button */}
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          {/* Edit Button */}
                          <button
                            className="btn btn-outline-success btn-sm me-2"
                            onClick={() => handleEdit(index)}
                          >
                            Edit
                          </button>
                          {/* Delete Button */}
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => handleDelete(index)}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
   
  );
};

export default LeaveManagement;