import React, { useEffect, useState } from 'react'
import { BiSolidPhoneCall, BiSolidUserCircle } from "react-icons/bi"
import { MdEmail, MdPassword } from "react-icons/md"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
// import logo from '../Image/appLogo/manohariiLogo.png'
import { Link } from 'react-router-dom'
import OtpInput from 'react-otp-input';
// import modules from '../Validations/Validate_msg'


export const OtpScreen = (props) => {
  const {handleOtp , otp , setOtp , resendOtp } = props
   

    const handlePaste: React.ClipboardEventHandler = (event) => {
        const data = event.clipboardData.getData('text');
    };



    const [time, setTime] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (time < 30) {
                setTime(time + 1);
            }
        }, 1000);
        return () => clearTimeout(timer); // Clear the timer when the component unmounts
    }, [time]);


    return (
        <section className='registerBg mt-5'>
            <div className='container-fluid p-0 m-0 '>
                <div className='col-md-6 mx-auto p-5'>
                    <div className='card border-0 shadow bg-transparent '>
                        {/* <img src={logo} className='img-fluid me-5 mt-4 ms-auto' alt='#' style={{ width: '100px' }} /> */}
                        <div className='card-body mx-auto'>
                            <form>
                                <div>
                                    <OtpInput
                                        value={otp}
                                        onPaste={handlePaste}
                                        onChange={setOtp}
                                        numInputs={6}
                                        renderSeparator={<span className='mx-1'></span>}
                                        renderInput={(props) => <input {...props} />}
                                        inputStyle={{ width: "40px", borderWidth: '1.4px', borderRadius: "5px", borderColor: "black" }}
                                    />
                                </div>
                                <div className='text-center mt-4'>
                                    <button className='btn btn-lg btn-success rounded-pill p-0 pe-2' onClick={(e) => handleOtp(e)}><IoCheckmarkDoneCircle size={40} className='me-2' />Verify OTP</button>
                                    {time < 30 ? <p className='mt-2' > Resend OTP in {30 - time} second.</p> : <p className='mt-2'><Link onClick={(e) => resendOtp(e)}>Resend OTP</Link> </p>}
                                </div>
                            </form>
                        </div>
                        {time < 30 ?
                            <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow={time} aria-valuemin="0" aria-valuemax="" style={{ height: "2px" }}>
                                <div class="progress-bar ThmPerpelBg" style={{ width: `${time * 3.4}%` }}></div>
                            </div> : null}
                    </div>
                </div>
            </div>
        </section>
    )
}
