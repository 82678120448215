import React, { useState } from 'react'

const EmployeAttendance = () => {

  const [attendanceData, setAttendanceData] = useState({
    // Define your form fields here
    // Example:
    employeeName: '',
    date: '',
    status: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAttendanceData({ ...attendanceData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setAttendanceData({
      employeeName: '',
      date: '',
      status: '',
    });
  };

  return (
    <div className="container mt-5">
      <div className="card bg-light">
        <div className="card-header"><b style={{color:"black"}}>ATTENDANCE FORM</b></div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            {/* Form fields go here */}
            <div className="mb-3">
              <label htmlFor="employeeName" className="form-label">
                Employee Name
              </label>
              <input
                type="text"
                className="form-control"
                id="employeeName"
                name="employeeName"
                value={attendanceData.employeeName}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="date" className="form-label">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                value={attendanceData.date}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="status" className="form-label">
                Status
              </label>
              <select
                className="form-select"
                id="status"
                name="status"
                value={attendanceData.status}
                onChange={handleInputChange}
              >
                <option value="">Select Status</option>
                <option value="present">Present</option>
                <option value="absent">Absent</option>
              </select>
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EmployeAttendance