import React, { useState } from 'react'


const CustomerDocuments = () => {

  const initialData = [
    { documentID: 1, documentName: 'Document 1', department: 'HR' },
    { documentID: 2, documentName: 'Document 2', department: 'Finance' },
    // Add more data as needed
  ];

  const [data, setData] = useState(initialData);
  const [editingId, setEditingId] = useState(null);
  const [updatedDocumentName, setUpdatedDocumentName] = useState('');
  const [updatedDepartment, setUpdatedDepartment] = useState('');

  const handleEdit = (id, documentName, department) => {
    setEditingId(id);
    setUpdatedDocumentName(documentName);
    setUpdatedDepartment(department);
  };

  const handleSave = (id) => {
    const updatedData = data.map((item) => {
      if (item.documentID === id) {
        return {
          ...item,
          documentName: updatedDocumentName,
          department: updatedDepartment,
        };
      }
      return item;
    });

    setData(updatedData);
    setEditingId(null);
  };

  const handleDelete = (id) => {
    const updatedData = data.filter((item) => item.documentID !== id);
    setData(updatedData);
  };
  return (
    <div className="container mt-4">
      <div className="card bg-light">
        <h5 style={{color:"black",marginTop:"8px",marginLeft:"6px"}}><b >Customer Documents</b></h5>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{color:"black"}}>Document ID</th>
                <th style={{color:"black"}}>Document Name</th>
                <th style={{color:"black"}}>Department</th>
                <th style={{color:"black"}}>Choose File</th>
                <th style={{color:"black"}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.documentID}>
                  <td>{item.documentID}</td>
                  <td>
                    {editingId === item.documentID ? (
                      <input
                        type="text"
                        value={updatedDocumentName}
                        onChange={(e) => setUpdatedDocumentName(e.target.value)}
                      />
                    ) : (
                      item.documentName
                    )}
                  </td>
                  <td>
                    {editingId === item.documentID ? (
                      <input
                        type="text"
                        value={updatedDepartment}
                        onChange={(e) => setUpdatedDepartment(e.target.value)}
                      />
                    ) : (
                      item.department
                    )}
                  </td>
                  <td>
                    <input type="file" />
                  </td>
                  <td>
                    {editingId === item.documentID ? (
                      <>
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => handleSave(item.documentID)}
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() =>
                            handleEdit(
                              item.documentID,
                              item.documentName,
                              item.department
                            )
                          }
                        >
                          Edit
                        </button>
                      </>
                    )}
                    <button
                      className="btn btn-danger btn-sm mx-2"
                      onClick={() => handleDelete(item.documentID)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CustomerDocuments