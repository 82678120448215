import api from "./api";

const register = (data) => {
  return api.post("createEmployee", data);
};

const login = (data) => {
  return api.post("empLogin", data);
};

const verifyOtp = (data) => {
  return api.post("veryfyOtp", data);
};

const getEmployee = (data) => {
  const queryString = new URLSearchParams(data).toString();
  return api.get(`getEmployes?${queryString}`);
};

const activateEmployee = (data) => {
  return api.post("activateUser", data);
};
const getAllLeads = () => {
  return api.get("/getLead");
};

const getDeadLeads = () => {
  return api.get("/getDeadLead");
};
const getLeadByEmpId = (data) => {
  return api.get(`/getLeadByEmpId/${data}`);
};
const getCounselorLead = (data) => {
  return api.get(`/getCounselorLead/${data}`);
};
const updateLeadByLeadId = (leadId, data) => {
  return api.put(`/updateLead/${leadId}`, data);
};
const getAllTelecaller = () => {
  return api.get("/getAllTelecaller");
};
const getAllCounselor = () => {
  return api.get("/getAllCounselor");
};
const getAllAdminDm = () => {
  return api.get("/getAllAdminDm");
};

const updateProfile = (data) => {
  return api.post("/updateEmp", data);
};
const getAllEmployees = () => {
  return api.get("/getAllEmployees");
};
const deleteLeadByLeadId = (leadId) => {
  return api.delete(`/deleteLead/${leadId}`);
};
const registerStudent = (data) => {
  return api.post(`/registerStudent`, data);
};
const getStudentByEmpId = (empId) => {
  return api.get(`/getStudentByEmpId/${empId}`);
};
const getLeadWithRegistrationStatusByEmpId = (empId) => {
  return api.get(`/getRegLeadByEmpId/${empId}`);
};
const studentAdmission = (data) => {
  return api.post("/admitStudent", data);
};
const uploadLeadeFile = (data) => {
  return api.post("/lead/upload", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const uploadLeadAudio = (data) => {
  return api.post("/uploadRecording", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const downloadAudio = async (leadId) => {
  try {
    const response = await api.get(`/downloadRecording/${leadId}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Return response data
    return response.data;
  } catch (error) {
    console.error("Error downloading audio:", error);
    // Rethrow the error to propagate it to the caller
    throw error;
  }
};

const getEmployeeByEmpId = (empId) => {
  return api.get(`/getEmployeeByEmpId/${empId}`);
};

const uploadLead = (file) => {
  return api.post("/lead/upload/", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const addLead = (data) => {
  return api.post("/addLead", data);
};

const getRegisteredLeadbyEmpId = (data) => {
  return api.get(`/getRegisteredLeadbyEmpId/${data}`);
};

const getRegisteredLeadbyAdmin = () => {
  return api.get(`/getRegisteredLeadbyAdmin`);
};

const getAdmissionLeadbyEmpId = (data) => {
  return api.get(`/getAdmissionLeadbyEmpId/${data}`);
};

const getAdmissionLeadbyAdmin = () => {
  return api.get(`/getAdmissionLeadbyAdmin`);
};

const deleteBulkLead = (data) => {
  return api.delete("/deleteBulkLead", { data: data });
};

const deadBulkLead = (data) =>{
  return api.put("/deadBulkLead",data);
}

const recoverBulkLead = (data) =>{
  return api.put("/recoverBulkLead",data);
}


const reporting = (data) => {
  return api.get("/reporting");
};



const getAllCandidates = (data) => {
  return api.get("getAllCandidates");
};

const deleteCandidate = (data) => {
  return api.delete("/deleteCandidateByPhone", {
    data: data,
  });
};

const updateCandidateById = (formData) => {
  return api.post(`/updateCandidateById`, formData);
};

const addCompany = (formData) => {
  return api.post(`/addCompany`, formData);
};
const updateCompanyById = (formData) => {
  return api.post(`/updateCompanyById`, formData);
};
const getAllCompany = (formData) => {
  return api.post(`/getAllCompany`, formData);
};

const getAllUniqueCompany = (formData) => {
  return api.post(`/getAllUniqueCompany`, formData);
};
const getCompanyById = (formData) => {
  return api.post(`/getCompanyById`, formData);
};
const deleteCompanyById = (formData) => {
  return api.post(`/deleteCompanyById`, formData);
};

const deleteVacancyById = (formData) => {
  return api.post(`/deleteVacancyById`, formData);
};


const deleteCandidateById = (data) => {
  return api.post("/deleteCandidateById", data);
};

const addInterviewScheduling = (data) => {
  return api.post("/scheduleInterview", data);
};
const getAllScheduledInterview = (data) => {
  return api.post("/getAllScheduledInterview", data);
};
const updateInterviewScheduleById = (data) => {
  return api.post("/updateInterviewScheduleById", data);
};
const deleteInterviewById = (data) => {
  return api.post("/deleteInterviewById", data);
};

const getCandidatebyStatus = (data) => {
  return api.post("/getCandidatebyStatus", data);
};

const getAllRecruiters = (data) => {
  return api.get("/getAllRecruiters", data);
};


const addOnboarding = (data) => {
  return api.post("/addOnboarding", data);
};

const updateOnboarding = (data) => {
  return api.post("/updateOnboarding", data);
};

const getJobProfileByCompany = (data) => {
  return api.post("/getJobProfileByCompany", data);
};

const docDownload = (data) => {
  return api.post("/download", data);
};

const docOrgDownload = (data) => {
  return api.post("/orgdownload", data);
};

const addVacancy = (data) => {
  return api.post("/addVacancy", data);
};

const getAllVacancy = (data) => {
  return api.post("/getAllVacancy", data);
};

const updateVacancyById = (data) => {
  return api.post("/updateVacancyById", data);
};

const uploadCandidate = (data) => {
  return api.post("/uploadCandidate", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const uploadCompany = (data) => {
  return api.post("/uploadCompany", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getOpenVacancy = (formData) => {
  return api.post(`/getOpenVacancy`, formData);
};


const bulkAssign = (formData) => {
  return api.post(`/bulkAssign`, formData);
};



const edMantra = {
  register,
  login,
  verifyOtp,
  getEmployee,
  activateEmployee,
  getAllLeads,
  getLeadByEmpId,
  updateLeadByLeadId,
  getAllCounselor,
  getAllTelecaller,
  getAllAdminDm,
  getCounselorLead,
  updateProfile,
  getAllEmployees,
  deleteLeadByLeadId,
  registerStudent,
  getStudentByEmpId,
  getLeadWithRegistrationStatusByEmpId,
  studentAdmission,
  uploadLeadeFile,
  uploadLeadAudio,
  downloadAudio,
  getEmployeeByEmpId,
  uploadLead,
  addLead,
  getRegisteredLeadbyEmpId,
  getRegisteredLeadbyAdmin,
  getAdmissionLeadbyEmpId,
  getAdmissionLeadbyAdmin,
  deleteBulkLead,
  reporting,
  getAllCandidates,
  deleteCandidate,
  updateCandidateById,
  deadBulkLead,
  getDeadLeads,
  recoverBulkLead,
  addCompany,
  getAllCompany,
  updateCompanyById,
  getCompanyById,
  deleteCompanyById,
  deleteCandidateById,
  addInterviewScheduling,
  getAllScheduledInterview,
  updateInterviewScheduleById,
  deleteInterviewById,
  getCandidatebyStatus,
  getAllRecruiters,
  addOnboarding,
  updateOnboarding,
  getJobProfileByCompany,
  docDownload,
  getAllVacancy,
  addVacancy,
  docOrgDownload,
  updateVacancyById,
  uploadCandidate,
  uploadCompany,
  getAllUniqueCompany,
  getOpenVacancy,
  bulkAssign,
  deleteVacancyById,
};

export default edMantra;
