import React, { useState, useEffect } from "react";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { SiMicrosoftexcel } from "react-icons/si";
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import loadinggif from "../../assets/images/loading.gif";
import filterFactory, {
    textFilter,
    dateFilter,
    
    selectFilter,
  } from "react-bootstrap-table2-filter";
  import BootstrapTable from "react-bootstrap-table-next";
  import {
    FaFileDownload,
    FaFileExport,
    FaAddressCard,
    FaCloudUploadAlt,
    FaFilter,
    FaExclamationTriangle,
  } from "react-icons/fa";
  import moment from 'moment';
const ATSReporting = () => {

    const [allVacancy, setAllVacancy] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allRecruiters, setAllRecruiters] = useState([]);
    const [allCompany, setAllCompany] = useState([]);
 // Function to handle row selection for all rows
 const handleRowSelectAll = (isSelected, rows) => {
    // Update selectedRows state when all rows are selected or deselected
    console.log(rows)
    if (isSelected) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const { ExportCSVButton } = CSVExport;
  const columns = [
    {
      dataField: "companyId",
      text: "Company Name",
      sort: "true",
      filter: textFilter(),
      formatter: (cell, row) => {
        const itemObj = allCompany?.find(
          (item) => item?.companyId === row?.companyId
        );

        return (
          <div className="d-flex">
            <>
              <p>{itemObj?.companyName}</p>
            </>
          </div>
        );
      },

    },

    {
      dataField: "primaryPOCMobile",
      text: "Primary POC Mobile",
      sort: "true",
      filter: textFilter(),

    },

    {
      dataField: "jobProfile",
      text: "Job Profile ",
      sort: "true",
      filter: textFilter(),
    },
    {
      dataField: "vacancyStatus",
      text: "Vacancy Status",
      sort: "true",
      filter: textFilter(),
    },
    {
      dataField: "location",
      text: "Location ",
      sort: "true",
      filter: textFilter(),
    },

    {
      dataField: "sector",
      text: "Sector",
      sort: "true",
      filter: textFilter(),
    },
    {
      dataField: "pdcDate",
      text: "PDC (Date)",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row) => {
        const today = moment();
        const pdcDate = moment(cell);

        // Check if PDC date is within the next 7 days
        const isCloseToDate = pdcDate.diff(today, "days") <= 7 && pdcDate.diff(today, "days") >= 0;

        return (
          <span>
            {moment(cell).format("YYYY-MM-DD")}{" "}
            {isCloseToDate && (
              <FaExclamationTriangle style={{ color: "red", animation: "blink 1s infinite" }} />
            )}
          </span>
        );
      },
    },
    {
      dataField: "createdBy",
      text: "Recruitment Manager",
      filter: selectFilter({
        options: allRecruiters.length > 0 ? allRecruiters.map(emp => ({
          value: emp.empId,
          label: `${emp.fname} ${emp.lname}`,
        })) : [],
        placeholder: 'Select or Search by Name',
      }),

      formatter: (cell, row) => {
        const itemObj = allRecruiters.find(emp => emp.empId === row.createdBy);

        return (
          <div className="d-flex">
            {itemObj ? (
              <p>{`${itemObj.fname} ${itemObj.lname}`}</p>
            ) : (
              <p>Loading...</p> // Consider showing "Data not available" if necessary
            )}
          </div>
        );
      },
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div className="d-flex">
          
          {/* <MdOutlineDelete
            size={25}
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row?.companyId)}
          /> */}


          


        </div>
      ),
    },
  ];
  
  // Function to handle row selection
  const handleRowSelect = (row, isSelected) => {

    setSelectedRows([...selectedRows, row])


  }; console.log(selectedRows)
// Custom CSS for blinking effect
const styles = `
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
`;
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 card border-0">
                        <p className="fs-3 fw-normal typed-text">ATS Reporting</p>
                    </div>

                    <div className="card col-md-4 border-0">
                        <div className="d-flex align-items-center">
                            <div>
                                <label htmlFor="exampleSelect">Select an Option:</label>
                                {/* <select
                                    id="exampleSelect"
                                    // value={selectedOption}
                                    onChange={""}
                                    className="form-select"
                                >
                                    <option value="">-- Please select --</option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                </select> */}

                                {/* {selectedOption && <p>You selected: {selectedOption}</p>} */}
                            </div>
                        </div>
                    </div>



                    <div className="card col-md-12 border-0">
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={loadinggif} alt="Loading" />
                            </div>
                        ) : (
                            <ToolkitProvider
                                keyField="companyId"
                                data={allVacancy}
                                columns={columns}
                                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                                search
                            >
                                {
                                    props => (
                                        <div>
                                            <ExportCSVButton className="btn btn-success btn-sm mb-2 float-end" {...(props.csvProps || {})} filename="MantrasERP_Record.csv">< SiMicrosoftexcel /> Export Record</ExportCSVButton>

                                            <div className="table-responsive cus-table">
                                                <style>{styles}</style> {/* Inject the blinking effect CSS */}
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    selectedRows={selectedRows}
                                                    filter={filterFactory()}
                                                    bootstrap4
                                                    keyField="companyId"
                                                    data={allVacancy}
                                                    columns={columns}
                                                    striped
                                                    hover
                                                    condensed
                                                    pagination={paginationFactory({ showTotal: true, })}
                                                    selectRow={{
                                                        mode: 'checkbox',
                                                        clickToSelect: true,
                                                        onSelect: handleRowSelect,
                                                        onSelectAll: handleRowSelectAll,
                                                    }}
                                                    filterPosition="top"
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        )}
                    </div>
                </div>
            </div>
        </>)
}

export default ATSReporting