import { createSlice} from "@reduxjs/toolkit";

const initialState = {
  userInfo:{} ,
};

export const SessionSlice = createSlice({
  name: "Session",
  initialState,
  reducers: {
    createSession: (state, action) => {
      state.userInfo=action.payload
    },
    removeSession: () => {},
  },
});

export const { createSession, removeSession } = SessionSlice.actions;
export default SessionSlice.reducer;
