import React from 'react'
import { Link } from 'react-router-dom'

export const ModuleAccordian = ({ data, currentRole }) => {
    return (
        <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target={`#Collapse${data?.index}`}
                aria-expanded="true" aria-controls="collapseTwo">
                {data?.icon}
                <span>{data?.moduleName}</span>
            </a>
            <div id={`Collapse${data?.index}`} className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                    {data?.links?.length > 0 && data?.links?.map((obj) => {
                        return <>{obj?.rolesAllowed?.includes(currentRole) && <Link className="collapse-item" to={obj?.path}>{obj?.title}</Link>}</>
                    })}
                </div>
            </div>
        </li>
    )
}
