/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import './Globalstyle.css'
import edMantra from "../../services/edMantra";
import EDLeadForm from "../../Components/EDLeadForm/EDLeadForm";
import EDModal from "../../Components/EDModal/EDModal";
import { FaWhatsapp } from "react-icons/fa";
import { MdEdit, MdEmail } from "react-icons/md";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EDAddLead from "../../Components/EDLeadForm/EDAddLead";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { FilterMatchMode } from "primereact/api";
import { FaAddressCard } from "react-icons/fa";
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import loadinggif from '../../assets/images/loading.gif'
import ToolkitProvider, { Search, CSVExport, pagination } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';



const SalesPipeline = () => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const [showModal, setShowModal] = useState(false);
  const [leadObject, setLeadObject] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [fileData, setFileData] = useState({});
  const [audioFile, setAdudioFile] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [allRemarks, setRemarks] = useState([]);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [statusFilter, setStatusFilter] = useState("All");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filteredData, setFilteredData] = useState([data]);
  const [addModal, setAddModal] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },

  })
  const [filterdate, setDate] = useState({ start_date: null, end_date: null })
  const [isLoading, setIsLoading] = useState(false);


  const handleEditButtonClick = (lead) => {
    setLeadObject(lead);
    setRemarks(lead.Remarks);
    setShowModal(true);
  };

  const getAssignedLead = async () => {
    try {
      const leads = await edMantra.getLeadByEmpId(data?.empId);
      if (leads) {
        setLeadData(leads?.data?.data);
      } else {
        setLeadData([])
      }
    } catch (error) {
      console.error(error, "error");
    }
  };

  const handleSave = async (val) => {
    // const prevRemark = leadObject?.remark;
    // val.remark = `${prevRemark} ${data?.empId + ": " + val.remark}`;
    try {
      const formData = new FormData();
      formData.append("audio", audioFile);
      formData.append("lead_id", val.LeadId);
      formData.append("emp_id", data?.empId);

      const response = await edMantra.updateLeadByLeadId(val.LeadId, val);
      if (response.status === 200 && audioFile) {
        const result = await edMantra.uploadLeadAudio(formData);
      }
      setShowModal(false);
      toast(response.data.message);
      getAssignedLead();
      setEditableRowIndex(null);
      setShowModal(false);
    } catch (error) {
      console.error(
        error.message || "An error occurred while updating the lead."
      );
    }
  };

  const handleFileChange = (e, index) => {
    const newFileData = { ...fileData };
    newFileData[index] = e.target.files[0];
    setFileData(newFileData);
  };

  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedField, setSortedField] = useState("leadstatus");

  const handleSort = (field) => {
    const order = sortOrder === "asc" ? "desc" : "asc";

    let sortedData;

    if (field === "all") {
      sortedData = leadData;
    } else {
      sortedData = leadData.sort((a, b) => {
        if (order === "asc") {
          return a[field].localeCompare(b[field]);
        } else {
          return b[field].localeCompare(a[field]);
        }
      });
    }

    setLeadData(sortedData);
    setSortOrder(order);
    setSortedField(field);
  };
  const getAllEmployees = async () => {
    try {
      const allEmployees = await edMantra.getAllEmployees();
      setAllEmployees(allEmployees.data.data);
    } catch (err) {
      console.error(err, "Error fetching data");
    }
  };
  const handleAssigneeUpdate = async (obj, empId) => {
    try {
      const response = await edMantra.updateLeadByLeadId(obj.LeadId, {
        ...obj,
        telecaller: empId,
        counselor: empId
      });
      toast(response.data.message);
      getAssignedLead();
    } catch (err) {
      console.error(err.message || "Something went wrong");
    }
  };

  const handleAddlead = (params) => {
    setAddModal(true);
  };
  const openwhatsApp = async (phone) => {
    try {
      if (!phone.startsWith("+91")) {
        phone = "+91" + phone;
      }
      const whatsappUrl = `https://wa.me/${phone}`;
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.error(error);
    }
  };

  const openEamil = (email) => {
    const recipient = email;
    const subject = "Subject of the email";
    const body = "Content of the email";
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  useEffect(() => {
    getAllEmployees();
    getAssignedLead();
  }, [refresh]);

 


  const formatDateforleadinout = (dateString) => { //for lead craetedAt and updatedAt
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };


  const formatDatefornextfollow = (dateString) => {
    const date = new Date(dateString);
    const options = {
      
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };

 //---------------source filter option--------------------


 const leadSources = [
  { value: '', label: 'Select a source' }, // Default option
  { value: 'Website', label: 'Website' },
  { value: 'Facebook Ad', label: 'Facebook Ad' },
  { value: 'Instagram Ad', label: 'Instagram Ad' },
  { value: 'Google Ad', label: 'Google Ad' },
  { value: 'Just Dial', label: 'Just Dial' },
  { value: 'Walk-In', label: 'Walk-In' },
  { value: 'Incoming Call', label: 'Incoming Call' },
  { value: 'Reference', label: 'Reference' },
  { value: 'Alumni', label: 'Alumni' },
  { value: 'Field Data', label: 'Field Data' },
  { value: 'Seminar', label: 'Seminar' },
  { value: 'Webinar', label: 'Webinar' },
  { value: 'Schools', label: 'Schools' },
  { value: 'Coaching\'s', label: 'Coaching\'s' },
  { value: 'Event', label: 'Event' },
  { value: 'Activities', label: 'Activities' },
  { value: 'Admission Centre', label: 'Admission Centre' },
  { value: 'Franchise', label: 'Franchise' },
  { value: 'Self-Generated', label: 'Self-Generated' },
  { value: 'Employment Mantras', label: 'Employment Mantras' },
  { value: 'Purchased Data', label: 'Purchased Data' },
  { value: 'WhatsApp', label: 'WhatsApp' },
  { value: 'WhatsApp Status', label: 'WhatsApp Status' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'GMB', label: 'GMB' },
  { value: 'WhatsApp Group', label: 'WhatsApp Group' },
  { value: 'Other', label: 'Other' },
  {Value: 'Google', label:'Google'}
];
const leadSourceFilter = selectFilter({
  options: leadSources
});
//-----------------------------------------------------------

  // -----------data table--------------------------------------------
  
  const columns = [{
    dataField: 'SrNo',
    text: 'Sr. No',
    sort: 'true',
    filter: textFilter()
  }, {
    dataField: 'fullName',
    text: 'Full Name',
    filter: textFilter()
  }, {
    dataField: 'mobile',
    text: 'Contact No.',
    filter: textFilter()
  }, 
  
  {
    dataField: 'city',
    text: 'City',
    filter: textFilter()
  },
  
  
  {
    dataField: 'query',
    text: 'Query',
    filter: textFilter()

  },
  {
    dataField: 'status',
    text: 'Status',
    filter: textFilter()

  }, {

    dataField: 'nextfollow',
    text: 'Next Follow Up Date',
    filter: dateFilter(),
    formatter: (cell, row) => formatDatefornextfollow(cell)

  }
    , {
    dataField: 'createdAt',
    text: 'Create Time',
    filter: dateFilter(),
    formatter: (cell, row) => formatDateforleadinout(cell)
  }, {
    dataField: 'updatedAt',
    text: 'Update Time',
    filter: dateFilter(),
    formatter: (cell, row) => formatDateforleadinout(cell)
  },
  {
    dataField: 'telecaller',
    text: 'Assign To',
    filter: selectFilter({
      options: allEmployees.map(emp => ({
        value: emp.empId,
        label: `${emp.fname} ${emp.lname}`
      })),
      placeholder: 'Select or Search by Name'
    }),
    formatter: (cell, row) => {
      const telecaller = allEmployees?.find(emp => emp?.empId === row?.telecaller);
      if (telecaller) {
        return `${telecaller.fname} ${telecaller.lname}`;
      } else {
        return 'Not Assigned'; // Or any other placeholder text
      }
    }
  }
   ,
  {
    dataField: 'source',
  text: 'Lead Source',
  filter: leadSourceFilter,
  },
  {
    dataField: 'actions', // Assuming 'actions' is the field in your data object that contains the actions data
    text: 'Actions',
    formatter: (cell, row, rowIndex, extraData) => {
      if (editableRowIndex === rowIndex) {
        return (
          <>
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => handleSave(row.LeadId, rowIndex)}
            >
              Save
            </button>
            <button
              className="btn btn-outline-primary"
              onClick={() => setEditableRowIndex(null)}
            >
              Cancel
            </button>
          </>
        );
      } else {
        return (
          <>
            <div className="d-flex">
              <div>
                <div className="mx-2 pointer-cursor">
                  <MdEdit
                    size={20}
                    color="black"
                    onClick={() => handleEditButtonClick(row)}
                  />
                </div>
              </div>
              
            </div>
            <div className="d-flex">
              <div className="mx-2 pointer-cursor">
                <FaWhatsapp
                  onClick={() => openwhatsApp(row?.mobile)}
                  size={20}
                  color="limegreen"
                />
              </div>
              <div className="ms-2 pointer-cursor">
                <MdEmail
                  style={{ cursor: "pointer" }}
                  onClick={() => openEamil(row.email)}
                  size={20}
                  color="#0072c6"
                />
              </div>
            </div>
          </>
        );
      }
    }
  }

  ];

console.log(allEmployees)

  //--------------data tabel--------------------------------------------

console.log(leadData);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 card border-0">
          <p className="fs-3 fw-normal manage-lead">Sales Pipeline</p>
        </div>


        <div className="card col-md-8 border-0">
          <div className="d-flex flex-wrap align-items-right">
          <button
                    type="button"
                    className="btn btn-warning btn-sm mr-2 mt-2"
                    onClick={() => handleAddlead()}
                  >
                    <FaAddressCard color={"black"} size={20} /> Add New Lead
                  </button>
          </div>
        </div>

        <div className=" card col-md-12 border-0">

        {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <img src={loadinggif} alt="Loading" /> 
        </div>
      ) : (



        <ToolkitProvider
            keyField="SrNo"
            data={leadData}
            columns={columns}
            exportCSV
          >
            {
              props => (
                <div>
                 

                  <div className="table-responsive cus-table">
                    <BootstrapTable
                      // Pass selectedRows as a prop to BootstrapTable
                      
                      filter={filterFactory()}
                      bootstrap4
                      keyField='SrNo'
                      data={leadData}
                      columns={columns}
                      striped
                      hover
                      condensed
                      pagination={paginationFactory({showTotal: true,})}
                      
                      filterPosition="top"
                    />
                  </div>
                </div>
              )
            }
          </ToolkitProvider>




           )} </div>


        <div className="card col-md-12 border-0">

          <div className="card bg-light m-2">
            <EDModal
              isOpen={showModal}
              handleClose={() => setShowModal(false)}
              size="xl"
            >
              <EDLeadForm
                handleSave={(obj) => handleSave(obj)}
                leadObject={leadObject}
                setFileData={(audio) => setAdudioFile(audio)}
                allRemarks={allRemarks}
              />
            </EDModal>
            <EDModal
                isOpen={addModal}
                handleClose={() => setAddModal(false)}
                size="xl"
              >
                <EDAddLead handleClose={(val) => setAddModal(false)} />
              </EDModal>
          </div>
        </div>

      </div>
    </div >

  );
};

export default SalesPipeline;
