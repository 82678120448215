import React from 'react'


const SalesQuotes = () => {
  return (
    <div className='text-center'>
     You are not authorized to access this feature. Please contact to admin.
    </div>
  )
}

export default SalesQuotes