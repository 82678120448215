import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from '../StrictModeDroppable/StrictModeDroppable';
import { IoMdCloseCircleOutline } from "react-icons/io";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


/**
 * Moves an item from one list to another list.
 */
const checkDuplicacy = (source, destination, droppableSource, droppableDestination, mainArray) => {
    let result = true
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);


    for (let item = 0; item <= destClone.length; item++) {
        if (droppableSource?.droppableId === '2' && mainArray[0].some((obj) => obj.permission === sourceClone[droppableSource.index]?.permission)) {
            result = false
            break;
        }
        else if (destClone[item]?.permission === sourceClone[droppableSource.index]?.permission) {
            result = false
            break;
        }
    }
    return result
}


const move = (source, destination, droppableSource, droppableDestination, mainArray) => {
    const result = {};
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    if (checkDuplicacy(source, destination, droppableSource, droppableDestination, mainArray)) {
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
        return result;
    } else {
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
        return result;
    }
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
});

export const EditPermissions = (props) => {
    const { isEditable, state, setState } = props;



    function onDragEnd(result) {
        const { source, destination } = result;
        // dropped outside the list

        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;


        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {

            const result = move(state[sInd], state[dInd], source, destination, state);
            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];

            setState(newState);
        }
    }

    return (
        <div class="row row-cols-2  row-cols-md-3">

            <DragDropContext onDragEnd={onDragEnd}>
                {state?.map((el, ind) => {
                    if (isEditable || ind !== 2) {
                        return (
                            <div class="col mx-auto my-3">
                                <StrictModeDroppable key={ind} droppableId={`${ind}`}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className='card m-2 h-100'
                                        >

                                            <div className='card-body '>
                                                {ind === 0 && <h6>Playable Module</h6>}
                                                {ind === 1 && <h6>Non Playable Module</h6>}
                                                {ind === 2 && <h6>All Modules</h6>}
                                                {el?.map((item, index) => (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={item.id + index}
                                                        index={index}
                                                        isDragDisabled={!isEditable}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <span ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps} class="badge text-bg-primary rounded-pill mx-1 m-1 shadow p-2">{item.permission}
                                                                {isEditable && <IoMdCloseCircleOutline size={15} className='ms-2' onClick={() => {
                                                                    const newState = [...state];
                                                                    newState[ind].splice(index, 1);
                                                                    setState(
                                                                        newState
                                                                    );
                                                                }} />}</span>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    )}
                                </StrictModeDroppable>
                            </div>
                        )
                    }
                }
                )}
            </DragDropContext>
        </div>
    )
}

