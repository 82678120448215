import React from 'react'

const AnalyticsAndReporting = () => {
  return (
    <div className="container">
        <div className='row'>

        </div>
    </div>
  )
}

export default AnalyticsAndReporting