import React from 'react'


const Profile = () => {
  return (
    <div>
      hello from profile
    </div>
  )
}

export default Profile