import React, { useState } from "react";
import DatePicker from "react-datepicker";

const PayrollManagement = () => {
  const [employeeId, setEmployeeId] = useState("");
  const [attendanceId, setAttendanceId] = useState("");
  const [totalDays, setTotalDays] = useState("");
  const [startMonth, setStartMonth] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [savedData, setSavedData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleSave = () => {
    const newData = {
      employeeId,
      attendanceId,
      totalDays,
      startMonth,
      endMonth,
    };

    if (editingIndex !== null) {
      // If editing an existing entry, update it
      const updatedData = [...savedData];
      updatedData[editingIndex] = newData;
      setSavedData(updatedData);
      setEditingIndex(null);
    } else {
      // If creating a new entry, add it to the list
      setSavedData([...savedData, newData]);
    }

    // Reset the form
    setEmployeeId("");
    setAttendanceId("");
    setTotalDays("");
    setStartMonth(null);
    setEndMonth(null);
  };

  const handleEdit = (index) => {
    // Set form fields to the values of the selected entry
    const selectedData = savedData[index];
    setEmployeeId(selectedData.employeeId);
    setAttendanceId(selectedData.attendanceId);
    setTotalDays(selectedData.totalDays);
    setStartMonth(selectedData.startMonth);
    setEndMonth(selectedData.endMonth);

    // Set the editingIndex to indicate that we are editing an existing entry
    setEditingIndex(index);
  };

  const handleDelete = (index) => {
    // Remove the selected entry from the saved data
    const updatedData = savedData.filter((_, i) => i !== index);
    setSavedData(updatedData);
  };

  return (
    <div className="container mt-4">
      <div className="card bg-light">
        <div className="card-body">
          <h5 className="card-title">
            <b>Payroll Management</b>
          </h5>
          <hr />
          <form>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="employeeId" className="form-label">
                    Employee ID
                  </label>
                  <select
                    className="form-select"
                    id="employeeId"
                    value={employeeId}
                    onChange={(e) => setEmployeeId(e.target.value)}
                  >
                    {/* Populate employee options from API or wherever you get them */}
                    <option value="1">Employee 1</option>
                    <option value="2">Employee 2</option>
                    {/* ... */}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="attendanceId" className="form-label">
                    Attendance ID
                  </label>
                  <select
                    className="form-select"
                    id="attendanceId"
                    value={attendanceId}
                    onChange={(e) => setAttendanceId(e.target.value)}
                  >
                    {/* Populate attendance options from API or wherever you get them */}
                    <option value="A1">Attendance 1</option>
                    <option value="A2">Attendance 2</option>
                    {/* ... */}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="totalDays" className="form-label">
                    Number of Total Days
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="totalDays"
                    value={totalDays}
                    onChange={(e) => setTotalDays(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="startMonth" className="form-label">
                    Start Month
                  </label>
                  <DatePicker
                    className="form-control"
                    id="startMonth"
                    selected={startMonth}
                    onChange={(date) => setStartMonth(date)}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label htmlFor="endMonth" className="form-label">
                    End Month
                  </label>
                  <DatePicker
                    className="form-control"
                    id="endMonth"
                    selected={endMonth}
                    onChange={(date) => setEndMonth(date)}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="card mt-4">
       
        <div className="card-body bg-light">
        <h5 className="card-title">Payroll Management Table</h5>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Employee ID</th>
                  <th>Attendance ID</th>
                  <th>Total Days</th>
                  <th>Start Month</th>
                  <th>End Month</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {savedData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.employeeId}</td>
                    <td>{data.attendanceId}</td>
                    <td>{data.totalDays}</td>
                    <td>
                      {data.startMonth && data.startMonth.toLocaleDateString()}
                    </td>
                    <td>
                      {data.endMonth && data.endMonth.toLocaleDateString()}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={() => handleEdit(index)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger ms-1"
                        onClick={() => handleDelete(index)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollManagement;