import React from 'react'


const Settings = () => {
  return (
    <div>
       hello from settings
    </div>
  )
}

export default Settings