import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSession } from "../../Redux/reducers/SessionSlice";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import logo1 from '../../assets/images/Logo DM.png'
import logo2 from '../../assets/images/Logo EM.png'
import { FaPowerOff } from "react-icons/fa";
import "./Header.css";

const Header = () => {
  const navigate = useNavigate();
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const dispatch = useDispatch();

  const handleLogout = () => {
    navigate("/");
    Cookies.remove("jwt");
    dispatch(createSession({}));
    window.location.reload();
  };

  // Move the greeting function outside of handleLogout
  const greeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return (<>
        Good Morning ,<br />
        Hope you have a productive day ahead!
      </>)
    } else if (currentHour < 18) {
      return (<>
        Good After Noon,<br />
        Hope you are doing great!
      </>)
    } else {
      return (<>
        Good Evening,<br />
        A relaxing and pleasant evening!!
      </>)
    }
  };

  return (
    <>
      <nav className="navbar gradient border-bottom border-body mb-5 sticky-lg-top" data-bs-theme="dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="https://mantraserp.com/">
            <div className="centered-container">
              <div className="logo-container">
                <img src={logo2} className="img-fluid mx-2 logo logo1" alt="logo" width='60px' />
                <img src={logo1} className="img-fluid mx-2 logo logo2" alt="logo" width='60px' />
              </div>
            </div>
          </a>
          <form className="d-flex" role="search">
            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-warning mr-2 btn-sm" type="submit">Search</button>
          </form>
{/* 
          <p className="text-light">
            Hi <span className="fw-medium text-warning">{data?.fname}</span>
            <img src="img/undraw_profile.svg" style={{ width: "20px" }} className="img-profile rounded-circle" alt="Profile" /><br />
            {greeting()}
            <button className="btn btn-outline-warning ml-2" onClick={handleLogout}>
              <FaPowerOff /> Logout
            </button>
          </p> */}
          <div className="dropdown">
            <button className="btn btn-transparent dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              Hi <span className="fw-medium text-warning">{data?.fname}</span>
              <img src="img/undraw_profile.svg" style={{ width: "20px" }} className="img-profile rounded-circle " alt="Profile" />
            </button>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li className="dropdown-item-text">
                {greeting()}
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li>
                <button className="dropdown-item" onClick={handleLogout}>
                  <FaPowerOff /> Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
