import React, { useState } from "react";

const DocumentManagement = () => {
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);

  const [documentId, setDocumentId] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [docFile, setDocFile] = useState("");
  const [employeDocument, setEmployeDocument] = useState("");

  const [viewDocument, setViewDocument] = useState();
  const handleToggle = () => {
    setViewDocument(true);
    setViewOfficeDocument(false)
  };

  const [viewOfficeDocumnet,setViewOfficeDocument]=useState()
  const handleToggl2 = () => {
    setViewDocument(false);
    setViewOfficeDocument(true)
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDocFileChange = (e) => {
    setDocFile(e.target.files[0]);
  };

  const handleSave = () => {
    if (file) {
      // Save the document details to the array
      const newDocument = {
        employeeId,
        employeeName,
        file,
      };

      setDocuments([...documents, newDocument]);

      // Perform save operation (e.g., send file to server)
      alert("File saved");
    } else {
      alert("Please select a file");
    }
  };

  const handleDocSave = () => {
    if (docFile) {
      // Save the document details to the array
      const newDocument = {
        documentId,
        documentName,
        docFile,
      };

      setEmployeDocument([...employeDocument, newDocument]);
     alert("File saved");
      setDocumentId("");
      setDocumentName("");
      
      setDocFile({});
    } else {
      alert("Please select a file");
    }
  };

  const handleDownload = (document) => {
    // Implement download logic here (e.g., create a download link)
    alert(`Downloading ${document.file.name}`);
  };
  const handleDeleteDocument = (document) => {
    const confirmDeletion = window.confirm(
      `Are you sure you want to delete the document with ID ${document.employeeId}?`
    );

    if (confirmDeletion) {
      const updatedDocuments = documents.filter(
        (doc) => doc.employeeId !== document.employeeId
      );
      setDocuments(updatedDocuments);
    }
  };

  const handleDocDownload = (document) => {
    alert(`Downloading ${document.file.name}`);
  };
  const handleDocDeleteDocument = (document) => {
    const confirmDeletion = window.confirm(
      `Are you sure you want to delete the document with ID ${document.employeeId}?`
    );

    if (confirmDeletion) {
      // Filter out the document from the array
      const updatedDocuments = employeDocument.filter(
        (doc) => doc.employeeId !== document.employeeId
      );
      setEmployeDocument(updatedDocuments);
    }
  };

  return (
    <div className="col-md-9">
      <div className="d-flex ">
        <button 
          className="btn btn-outline-primary col-md-7 border-4"
          onClick={handleToggle}
          
        >                                                                                           
        Employee Document
        </button>
        
        <button
          className="btn btn-outline-primary col-md-8 border-0"
          onClick={handleToggl2}
        >
         Office Documnet
        </button>
      </div>

      {viewDocument ? (
        <div className="card bg-light " style={{height:"100%",marginTop:"60px"}}>
          <div className="card-body ">
            <h5>
              <b style={{ color: "black" }}> Employee Document Management</b>
            </h5>
            <div className="table-responsive">
              <table className="table table-sm ">
                <thead>
                  <tr>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Choose File</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={employeeName}
                        onChange={(e) => setEmployeeName(e.target.value)}
                      />
                    </td>
                    <td>
                      <input type="file" onChange={handleFileChange} />
                    </td>
                    <td>
                      <button className="btn btn-success" onClick={handleSave}>
                        Save
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="my-4" />
            {/* Display documents */}
            {documents.length > 0 && (
              <div>
                <h5>
                  <b>Documents</b>
                </h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Employee ID</th>
                      <th>Employee Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((document, index) => (
                      <tr key={index}>
                        <td>{document.employeeId}</td>
                        <td>{document.employeeName}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleDownload(document)}
                          >
                            Download
                          </button>
                          <button
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => handleDeleteDocument(document)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="card bg-light  "style={{marginTop:"60px"}}>
          <div className="card-body  ">
            <h5>
              <b style={{ color: "black" }}>Office Document Management</b>
            </h5>
            <div className="table-responsive">
              <table className="table table-sm ">
                <thead>
                  <tr>
                    <th>Document ID</th>
                    <th>Document Name</th>
                    <th>Choose File</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        value={documentId}
                        onChange={(e) => setDocumentId(e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={documentName}
                        onChange={(e) => setDocumentName(e.target.value)}
                      />
                    </td>
                    <td>
                      <input type="file" onChange={handleDocFileChange} />
                    </td>
                    <td>
                      <button
                        className="btn btn-success"
                        onClick={handleDocSave}
                      >
                        Save
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr className="my-4" />
            {/* Display documents */}
            {employeDocument.length > 0 && (
              <div>
                <h5>
                  <b>Documents</b>
                </h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Document ID</th>
                      <th>Document Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeDocument.map((document, index) => (
                      <tr key={index}>
                        <td>{document.documentId}</td>
                        <td>{document.documentName}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleDocDownload(employeDocument)}
                          >
                            Download
                          </button>
                          <button
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => handleDocDeleteDocument(employeDocument)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentManagement;
