import React from 'react'


const Files = () => {
  return (
   <div className='text-center'>
   You are not authorized to access this feature. Please contact to admin.
   </div>
  )
}

export default Files