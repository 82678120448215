import React, { useEffect,  useState } from "react";

import edMantra from "../../services/edMantra";
import ChartComponent from "../../Components/Chart/ChartComponent";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

const Track = () => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const [TotalLeads, setTotalLeads] = useState([]);

  const registration = TotalLeads?.filter((item) => item.status === "Registration").length;
  const admission = TotalLeads?.filter((item) => item.status === "Admission").length;
  const job = TotalLeads?.filter((item) => item.status === "For Job").length;
  const closed = TotalLeads?.filter((item) => item.status === "Close").length;
  const assignedLeads = TotalLeads?.filter((item) => item.status === "Assigned").length;
  const dead = TotalLeads?.filter((item) => item.status === "Dead Lead").length;
  const coldLeads = TotalLeads?.filter(
    (item) => item.status === "Cold Lead"
  ).length;
  const newLeads = TotalLeads?.filter(
    (item) => item.status === "New Lead"
  ).length;
  const hotLeads = TotalLeads?.filter(
    (item) => item.status === "Hot Lead"
  ).length;
  const warmsLeads = TotalLeads?.filter(
    (item) => item.status === "Warm Lead"
  ).length;

  const allLeads = {
    totalLeads: TotalLeads?.length,
    closeLeads: closed,
    deadLeads: dead,
    liveLeads: TotalLeads?.length - closed,
    newLeads,
    hotLeads,
    warmsLeads,
    coldLeads,
    assignedLeads,
    registration,
    admission,
    job,
  };
  const chartData = {
    labels: [
      "Total Leads",
      "Close Leads",
      "Dead Leads",
      "Live Leads",
      "New Leads",
      "Hot Leads",
      "Warm Leads",
      "Cold Leads",
      "Assigned Leads",
      
    ],
    values: [
      allLeads?.totalLeads,
      allLeads?.closeLeads,
      allLeads?.deadLeads,
      allLeads?.liveLeads,
      allLeads?.newLeads,
      allLeads?.hotLeads,
      allLeads?.warmsLeads,
      allLeads?.coldLeads,
      allLeads?.assignedLeads,
     
    ],
  };

  // const [selectedRange, setSelectedRange] = useState("12 months"); // Default selected range

  // const handleRangeChange = (selectedRange) => {
  //   setSelectedRange(selectedRange);
  // };

  const totalLeads = async () => {
    try {
      const res = await edMantra.getAllLeads();
      setTotalLeads(res?.data?.data);
    } catch (error) {}
  };

  const getAssignedLead = async () => {
    try {
      const leads = await edMantra.getLeadByEmpId(data?.empId);
      if (leads) {
        setTotalLeads(leads?.data?.data);
      }
    } catch (error) {
      console.error(error, "error");
    }
  };
  useEffect(() => {
    if (data?.role?.roleName === "AdminDm" || data?.role?.roleName === "SuperAdmin") {
      totalLeads();
    } else {
      getAssignedLead();
    }
  }, [data]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2 mb-2">
        <a href="Manage" style={{textDecoration: "none"}}>  <div
            className="card border shadow h-100 py-2"
            style={{ backgroundColor: "rgba(177, 156, 217, 0.9)" }}
          >
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div
                    className="h6 text-xs font-weight-bold text-black text-uppercase mb-2"
                    
                  >
                    Total Leads
                  </div>
                  <div className="h1 mb-0 font-weight-bold text-black">
                    {allLeads.totalLeads}  <i className="fas fa-calendar  text-black"></i>
                  </div>
                </div>
                
              </div>
            </div>
          </div></a>
        </div>

        <div className="col-md-2 mb-2">
        <Link to={{ pathname: '/Manage', search: '?status=Close' }} style={{textDecoration: "none"}}> <div
            className="card border shadow h-100 py-2"
            style={{ backgroundColor: "rgba(75, 192, 192, 0.9)" }}
          >
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div
                    className="h6 text-xs font-weight-bold text-black text-uppercase mb-2"
                    
                  >
                    Close Leads
                  </div>
                  <div className="h1 mb-0 font-weight-bold text-black">
                    {allLeads.closeLeads} <i className="fa fa-check-circle  text-black"></i>
                  </div>
                </div>
                
              </div>
            </div>
          </div></Link>
        </div>

        <div className="col-md-2 mb-2">
        <a href="Registration" style={{textDecoration: "none"}}> <div
            className="card border shadow h-100 py-2"
            style={{ backgroundColor: "rgba(255, 99, 132, 0.9)" }}
          >
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div
                    className="h6 text-xs font-weight-bold text-black text-uppercase mb-2"
                    
                  >
                    Registered
                  </div>
                  <div className="h1 mb-0 font-weight-bold text-black">
                    {allLeads.registration}  <i className="fa fa-book  text-black"></i>
                  </div>
                </div>
                
              </div>
            </div>
          </div></a>
        </div>

        <div className="col-md-2 mb-2">
        <a href="Admission" style={{textDecoration: "none"}}> <div
            className="card border shadow h-100 py-2"
            style={{ backgroundColor: "rgba(255, 153, 51, 0.9)" }}
          >
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div
                    className="h6 text-xs font-weight-bold text-black text-uppercase mb-2"
                    
                  >
                    Admission
                  </div>
                  <div className="h1 mb-0 font-weight-bold text-black">
                    {allLeads.admission}  <i className="fa fa-flag-checkered  text-black"></i>
                  </div>
                </div>
                
              </div>
            </div>
          </div></a>
        </div>

        <div className="col-md-2 mb-2">
        <a href="Manage" style={{textDecoration: "none"}}> <div
            className="card border shadow h-100 py-2"
            style={{ backgroundColor: "rgba(51, 51, 255, 0.9)" }}
          >
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div
                    className="h6 text-xs font-weight-bold text-black text-uppercase mb-2"
                    
                  >
                    For Job
                  </div>
                  <div className="h1 mb-0 font-weight-bold text-black">
                    {allLeads.job} <i className="fa fa-suitcase  text-black"></i>
                  </div>
                </div>
               
              </div>
            </div>
          </div></a>
        </div>

        <div className="col-md-2 mb-2">
          {" "}
          <a href="Manage" style={{textDecoration: "none"}}> <div
            className="card shadow h-100 py-2"
            style={{ backgroundColor: "rgba(51, 255, 153, 0.9)" }}
          >
            <div className="card-body">
              <div className="text-center">
                {" "}
                <div
                  className="h6 text-xs font-weight-bold text-black text-uppercase mb-2"
                  
                >
                  Live Leads 
                </div>
                <div className="h1 mb-0 font-weight-bold text-black">
                  {allLeads.liveLeads} <i class="fa fa-line-chart" aria-hidden="true"></i>
                </div>
                <div className="row mt-2">

                {/* <div className="col">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                      New
                    </div>
                    <div className="h6 mb-0 font-weight-bold text-blqack">
                      {allLeads.newLeads}
                    </div>
                  </div> */}
                  <div className="col">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                      Hot
                    </div>
                    <div className="h6 mb-0 font-weight-bold text-blqack">
                      {allLeads.hotLeads}
                    </div>
                  </div>
                  {/* <div className="col">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                      Cold
                    </div>
                    <div className="h6 mb-0 font-weight-bold text-black">
                      {allLeads.coldLeads}
                    </div>
                  </div> */}
                  <div className="col">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                      Warm
                    </div>
                    <div className="h6 mb-0 font-weight-bold text-black">
                      {allLeads.warmsLeads}
                    </div>
                  </div>
                  {/* <div className="col">
                    <div className="h6 text-xs font-weight-bold text-black text-uppercase">
                      Dead
                    </div>
                    <div className="h6 mb-0 font-weight-bold text-black">
                      {allLeads.deadLeads}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div></a>
        </div>

        {/* Graph */}
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Leads Data Graph
              </h6>
            </div>
            <div className="card-body">
              <div className="chart-container">
                {/* <canvas ref={chartRef}></canvas> */}
                <ChartComponent data={chartData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Track;
