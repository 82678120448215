// components/EDLeadForm.js
import React, { useState, useEffect } from "react";
import edMantra from "../../services/edMantra";
import { useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ErrorPopup from '../Errors/ErrorPopup'; // Import ErrorPopup component
import moment from 'moment'; // Import moment library for date formatting

const EDLeadForm = (props) => {
  const { leadObject, handleSave, setFileData, allRemarks = [] } = props;
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  const [errors, setErrors] = useState({});


  // Initialize editableLead state with leadObject and nextfollow
  const [editableLead, setEditableLead] = useState({
    ...leadObject,
    nextfollow: null // You can set nextfollow to null initially
  });

  const [telecallers, setTelecaller] = useState([]);
  const [counselors, setCounselor] = useState([]);
  const [adminDm, setAdminDm] = useState([]);
  // Handle nextfollow separately
  const [selectedDate, setSelectedDate] = useState(
    editableLead.nextfollow ? new Date(editableLead.nextfollow) : null
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  console.log(allRemarks)
  console.log(leadObject, "*************************")

  const handleFileChange = (e) => {
    setFileData(e.target.files[0]);
  };

  const getAllTelecaller = async () => {
    const result = await edMantra.getAllTelecaller();
    setTelecaller(result?.data?.data);
  };
  const getAllCounselor = async () => {
    const result = await edMantra.getAllCounselor();
    setCounselor(result?.data?.data);
  };

  const getAllAdminDm = async () => {
    const result = await edMantra.getAllAdminDm();
    setAdminDm(result?.data?.data);
  };
  const formatDateforleadremarkdate = (dateString) => { //for lead craetedAt and updatedAt
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Include this option for 12-hour format
    };
    return date.toLocaleDateString('en-GB', options);
  };




  //-----------------validation-------------------------------------


  const validateForm = (editableLead) => {
    let errors = {};
    if (!/^[a-zA-Z\s]+$/.test(editableLead.fullName)) {
      errors.fullName = "Only Character is allowed in Full Name";
    }

    if (!/^\d{10}$/.test(editableLead.mobile)) {
      errors.mobile = 'Mobile number must be exactly 10 digits long';
    }

    if (!/^\d{10}$/.test(editableLead.altMobile)) {
      errors.altMobile = 'Alternative Phone number must be exactly 10 digits long';
    }

    if (!/\S+@\S+\.\S+/.test(editableLead.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!/^[a-zA-Z\s]+$/.test(editableLead.state)) {
      errors.state = "Only letters are allowed in State/Territory";
    }
    if (!/^[a-zA-Z\s]+$/.test(editableLead.city)) {
      errors.city = "Only Character is allowed in City/Town";
    }

    if (!editableLead.query || editableLead.query === "") {
      errors.query = "Please select a lead query subject";
    }

    if (!editableLead.status || editableLead.status === "") {
      errors.status = "Please put this lead under a status";
    }

    if (!/^\d{1,2}(\.\d{1,2})?$/.test(editableLead.rating)) {
      errors.rating = "Rating should be a decimal number up to 10.00";
    }

    if (editableLead.nextfollow === null) {
      errors.nextfollow = "You cannot leave the next follow-up date blank";
    }


    return errors;
  };

  const handleSaveLead = () => {
    const errors = validateForm(editableLead);
    if (Object.keys(errors).length === 0) {
      // No validation errors, proceed with saving
      handleSave(editableLead); // Call handleSave function passed from props
    } else {
      // Validation errors present, set errors to state
      setErrors(errors);
    }
  };


  //------------------validations-------------------------------


  useEffect(() => {
    getAllCounselor();
    getAllTelecaller();
    getAllAdminDm();
  }, [leadObject]);


  return (
    <section >

      <div className="row p-0 m-0 "  >
        <div className="col-md-8 mx-auto">
          <div>
            <div className="row">
              {Object.keys(errors).length > 0 && <ErrorPopup errors={errors} />}
              {/* {data?.role?.roleName == "Telecaller" ? null : ( */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Full Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editableLead?.fullName || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        fullName: e.target.value,
                      }))
                    }
                    required={true}
                  />
                </div>
              </div>
              {/* )} */}
              {/* {data?.role?.roleName == "Telecaller" ? null : ( */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Mobile Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editableLead?.mobile || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        mobile: e.target.value,
                      }))
                    }
                    required={true}
                  />
                </div>
              </div>
              {/* )} */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Alternate Mobile Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editableLead?.altMobile || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        altMobile: e.target.value,
                      }))
                    }
                    required={true}
                  />
                </div>
              </div>

              {/* {data?.role?.roleName == "Telecaller" ? null : ( */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editableLead?.email || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                    required={true}
                  />
                </div>
              </div>
              {/* )} */}

              {/* {data?.role?.roleName == "Telecaller" ? null : ( */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>State:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editableLead?.state || ""}
                    onChange={(e) => {
                      console.log("Input value:", (e.target.value));
                      setEditableLead((prevState) => ({
                        ...prevState,
                        state: e.target.value,
                      }));
                    }}
                    required={true}
                  />
                </div>
              </div>

              {/* )} */}


              {/* {data?.role?.roleName == "Telecaller" ? null : ( */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>City:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editableLead?.city || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        city: e.target.value,
                      }))
                    }
                    required={true}
                  />
                </div>
              </div>
              {/* )} */}


              {/* {data?.role?.roleName == "Telecaller" ? null : ( */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Education:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editableLead?.pincode || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        pincode: e.target.value,
                      }))
                    }
                    required={true}
                  />
                </div>
              </div>
              {/* )} */}


              {/* {data?.role?.roleName == "Telecaller" ? null : ( */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Lead Query:</label>
                  <select
                    className="form-control"
                    value={editableLead?.query || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        query: e.target.value,
                      }))
                    }
                    required={true}
                  >
                    <option value="">
                      <b>Select Lead Query</b>
                    </option>
                    <option value="Digital Marketing (Full Course)">
                      Digital Marketing (Full Course)
                    </option>
                    <option value="Digital Marketing (2.5 Months)">
                      Digital Marketing (2.5 Months)
                    </option>
                    <option value="Digital Marketing (45 Days)">
                      Digital Marketing (45 Days)
                    </option>
                    <option value="Website Designing (Wordpress)">
                      Website Designing (Wordpress)
                    </option>
                    <option value="Advanced Graphic Designing">
                      Advanced Graphic Designing
                    </option>
                    <option value="Coral Draw">Coral Draw</option>
                    <option value="Illustrator">Illustrator</option>
                    <option value="Photoshop">Photoshop</option>
                    <option value="Motion Graphics">Motion Graphics</option>
                    <option value="Video Editing">Video Editing</option>
                    <option value="Human Resource Management">
                      Human Resource Management
                    </option>
                    <option value="Payroll Management">Payroll Management</option>
                    <option value="Talent Acquistion">Talent Acquistion</option>
                    <option value="MIS & Advance Excel">
                      MIS & Advance Excel
                    </option>
                    <option value="MIS+Tally">MIS+Tally</option>
                    <option value="Tally">Tally</option>
                    <option value="PowerBI">PowerBI</option>
                    <option value="Data Science">Data Science</option>
                    <option value="ADCA">ADCA</option>
                    <option value="Certification In Computer Application (CCA)">
                      Certification In Computer Application (CCA)
                    </option>
                    <option value="Java">Java</option>
                    <option value="Advance Python">Advance Python</option>
                    <option value="Database MYSQL">Database MYSQL</option>
                    <option value="Flutter">Flutter</option>
                    <option value="Salesforce CRM">Salesforce CRM</option>
                    <option value="C Language">C Language</option>
                    <option value="C++ Language">C++ Language</option>
                    <option value="Hindi & English Typing">
                      Hindi & English Typing
                    </option>
                    <option value="English Speaking">English Speaking</option>
                    <option value="Artificial Intelligence(AI)">
                      Artificial Intelligence(AI)
                    </option>
                    <option value="CCC">CCC</option>
                    <option value="A Level">A Level</option>
                    <option value="O Level">O Level</option>
                    <option value="Personality Development">
                      Personality Development
                    </option>
                    <option value="Employability Skills">
                      Employability Skills
                    </option>
                    <option value="Combo Course">
                      Combo Course
                    </option>
                  </select>
                </div>
              </div>
              {/* )} */}

              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label htmlFor="Status">Lead Status:</label>
                  <select
                    id="status"
                    className="form-control"
                    value={editableLead?.status || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        status: e.target.value,
                      }))
                    }
                    required={true}
                  >
                    <option value="">Select Lead Status </option>
                    <option value="New Lead">⚪️New Lead</option>
                    <option value="Hot Lead"> 🔴Hot Lead</option>
                    <option value="Warm Lead">🟡Warm Lead </option>
                    <option value="Cold Lead"> 🟤Cold Lead</option>
                    <option value="Dead Lead"> ⚫Dead Lead</option>
                    <option value="Visited">🟠Visited</option>
                    <option value="Registration"> 🔵Registration</option>
                    <option value="Admission"> 🟢Admission</option>
                    <option value="Close"> 🟣Close</option>
                    <option value="For Job"> 🔵For Job</option>
                    <option value="Agency Work">⭕Agency Work</option>

                  </select>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Comments:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editableLead?.remark || ""}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setEditableLead(prevState => ({
                        ...prevState,
                        remark: newValue,
                      }));
                    }}
                    required={true}
                  />

                </div>
              </div>


              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Next Follow Up date:</label>
                  <DatePicker
                    className="form-control"
                    selected={selectedDate}
                    onChange={(date) => {
                      // Check if date is not null
                      if (date) {
                        // Set time part of the date to 11:00 AM IST
                        date.setHours(11);
                        date.setMinutes(0);
                        date.setSeconds(0);
                        date.setMilliseconds(0);
                      }

                      setSelectedDate(date);

                      // Format the date to the desired format
                      const formattedDate = date ? moment.utc(date).format("YYYY-MM-DDTHH:mm:ss[Z]") : null;

                      // Update state with the formatted date
                      setEditableLead((prevState) => ({
                        ...prevState,
                        nextfollow: formattedDate,
                      }));
                    }}
                    placeholderText="Select Next Follow Up date"
                    required={true}
                  />

                </div>
              </div>

              {/* {data?.role?.roleName == "Telecaller" ? null : ( */}
              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Assign To:</label>
                  <select
                    className="form-control"
                    value={editableLead?.telecaller || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        telecaller: e.target.value,
                      }))
                    }
                    required
                  >
                    <option value="Not assigned">Select Assignee</option>
                    {[...telecallers, ...counselors, ...adminDm].map((item, index) => (
                      <option key={index} value={item?.empId}>
                        {`${item?.fname} ${item?.mname} ${item?.lname}`}
                      </option>
                    ))}



                  </select>
                </div>
              </div>


              <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Lead Source</label>
                  <select
                    type="text"
                    className="form-control"
                    value={editableLead?.source || ""}
                    onChange={(e) =>
                      setEditableLead((prevState) => ({
                        ...prevState,
                        source: e.target.value,
                      }))
                    }
                    required={true}
                  >
                    <option value="">Select a source</option>
                    <option value="Website">Website</option>
                    <option value="Facebook Ad">Facebook Ad</option>
                    <option value="Instagram Ad">Instagram Ad</option>
                    <option value="Google Ad">Google Ad</option>
                    <option value="Just Dial">Just Dial</option>
                    <option value="Walk-In">Walk-In</option>
                    <option value="Incoming Call">Incoming Call</option>
                    <option value="Reference">Reference</option>
                    <option value="Alumni">Alumni</option>
                    <option value="Field Data">Field Data</option>
                    <option value="Seminar">Seminar</option>
                    <option value="Webinar">Webinar</option>
                    <option value="Schools">Schools</option>
                    <option value="Coaching's">Coaching's</option>
                    <option value="Event">Event </option>
                    <option value="Activities">Activities</option>
                    <option value="Admission Centre">Admission Centre</option>
                    <option value="Franchise">Franchise</option>
                    <option value="Self-Generated">Self-Generated</option>
                    <option value="Employment Mantras">Employment Mantras</option>
                    <option value="Purchased Data">Purchased Data</option>
                    <option value="WhatsApp">WhatsApp</option>
                    <option value="WhatsApp Status">WhatsApp Status</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="GMB">GMB</option>
                    <option value="WhatsApp Group">WhatsApp Group</option>
                    <option value="Other">Other</option>
                    <option value="Google">Google</option>

                  </select>
                </div>
              </div>

              {editableLead.source === "Reference" && (
                <>
                  <div className="col-md-4 mb-3">
                    <div className="form-group">
                      <label>Person Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableLead?.personName || ""}
                        placeholder="Referred By"
                        onChange={(e) =>
                          setEditableLead((prevState) => ({
                            ...prevState,
                            personName: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <div className="form-group">
                      <label>Person Contact</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableLead?.personContact || ""}
                        placeholder="Contact Number"
                        onChange={(e) =>
                          setEditableLead((prevState) => ({
                            ...prevState,
                            personContact: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  </>
              )}




              {/* )} */}

              {/* <div className="col-md-4 mb-3">
                <div className="form-group">
                  <label>Upload Recording:</label>
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={(e) => handleFileChange(e)}
                    required
                  />
                </div>
              </div> */}
            </div>



            <button
              className="btn btn-primary btn-block"
              type="submit"
              onClick={() => handleSaveLead(editableLead)}
            >
              Save
            </button>

          </div>
        </div>
        <div className="col-md-4 mx-auto border m-2 pt-2" >
          <h5>Remarks</h5>
          <div className="p-2" style={{ height: '30rem', overflowY: 'scroll' }}>
            {allRemarks?.length > 0 ?
              allRemarks?.map((obj, index) => {
                return (
                  <>
                    <div className="card p-0 mt-2 border-0 shadow ">
                      <div className="card-body p-2 m-0">
                        <p className=" text-dark">{obj?.remark}</p>
                        <div className="d-flex  text-secondary p-0 m-0 justify-content-between">
                          <p style={{ fontSize: '14px' }}>{formatDateforleadremarkdate(obj?.createdAt)}</p>
                          <p style={{ fontSize: '14px' }}>{`${obj?.Employee?.fname} (${obj?.Employee?.role})`}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }) : <p className="fs-6 text-secondary">Remarks not available</p>}
          </div>

        </div>
      </div>
    </section>

  );
};

export default EDLeadForm;
