import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import Track from "../LeadManagementPages/Track";
import { useSelector } from "react-redux";
import { json } from "react-router-dom";

const Dashboard = () => {
  const { data } = useSelector((state) => state?.currentSession?.userInfo);
  // const [conversionLeads, setConversionLeads] = useState(12500);
  // const [totalUsers, setTotalUsers] = useState(11230);
  // const [totalTrainee, setTotalTrainee] = useState(11215);
  // const [totalPlacements, setTotalPlacements] = useState(1012);

  // const [areaChartConfig, setAreaChartConfig] = useState(null);
  // const [pieChartConfig, setPieChartConfig] = useState(null);

  // useEffect(() => {
  //   // Earnings Overview Chart
  //   const areaChartData = {
  //     labels: ["January", "February", "March", "April", "May", "June"],
  //     datasets: [
  //       {
  //         label: "Earnings Overview",
  //         borderColor: "rgba(75, 192, 192, 1)",
  //         backgroundColor: "rgba(75, 192, 192, 0.2)",
  //         data: [10, 20, 30, 40, 50, 60],
  //       },
  //     ],
  //   };

  //   const areaChartOptions = {
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     scales: {
  //       x: {
  //         display: true,
  //         title: {
  //           display: true,
  //           text: "Month",
  //         },
  //       },
  //       y: {
  //         display: true,
  //         title: {
  //           display: true,
  //           text: "Earnings",
  //         },
  //       },
  //     },
  //   };

  //   setAreaChartConfig({
  //     type: "line",
  //     data: areaChartData,
  //     options: areaChartOptions,
  //   });

  //   // Revenue Sources Pie Chart
  //   const pieChartData = {
  //     labels: ["Direct", "Social", "Referral"],
  //     datasets: [
  //       {
  //         data: [30, 40, 30],
  //         backgroundColor: ["#4e73df", "#1cc88a", "#36b9cc"],
  //       },
  //     ],
  //   };

  //   const pieChartOptions = {
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     legend: {
  //       position: "bottom",
  //     },
  //   };

  //   setPieChartConfig({
  //     type: "pie",
  //     data: pieChartData,
  //     options: pieChartOptions,
  //   });
  // }, []);

  // useEffect(() => {
  //   if (areaChartConfig) {
  //     createChart("myAreaChart", areaChartConfig);
  //   }
  //   if (pieChartConfig) {
  //     createChart("myPieChart", pieChartConfig);
  //   }
  // }, [areaChartConfig, pieChartConfig]);

  // const createChart = (id, config) => {
  //   const existingChart = Chart.getChart(id);
  //   if (existingChart) {
  //     existingChart.destroy();
  //   }

  //   const ctx = document.getElementById(id).getContext("2d");
  //   new Chart(ctx, config);
  // };

  const viewByPermission = () => {
    switch (data?.role.roleName) {
      case "SuperAdmin":
        return <Track />;
      case "AdminDm":
        return <Track />;
      case "Telecaller":
        return <Track />;
      default:
        return (
          <>
            <div className="card border-0 shadow w-50 mx-auto mt-5">
              <div className="card-body text-center">
                <h2 className="fw-bold">Hello there!</h2>
                <p>Ready to explore and manage your data?</p>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>{viewByPermission()}</>
    // <div className="container-fluid bg-white">
    //   <div className="d-sm-flex align-items-center justify-content-between mb-5">
    //     <p className="h3 mb-0 text-gray-800">
    //       <b>Dashboard</b>
    //     </p>
    //     <a
    //       href="#"
    //       className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
    //       onClick={()=>window.alert("You are not authorized to access this feature. Please contact to admin.")}
    //     >
    //       <i className="fas fa-download fa-sm text-white-50"></i> Generate
    //       Report
    //     </a>
    //   </div>

    //   <div className="row">
    //     <div className="col-lg-12">
    //       <div className="row">
    //         <div className="col-xl-3 col-md-6 mb-4">
    //           <div className="card border-left-primary shadow h-100 py-2">
    //             <div className="card-body">
    //               <div className="row no-gutters align-items-center">
    //                 <div className="col mr-2">
    //                   <div
    //                     className="h5 text-xs font-weight-bold text-primary text-uppercase mb-2"
    //                     style={{ fontSize: "1rem" }}
    //                   >
    //                     Total Conversion Leads
    //                   </div>
    //                   <div className="h5 mb-0 font-weight-bold text-gray-800">
    //                     {conversionLeads}
    //                   </div>
    //                 </div>
    //                 <div className="col-auto">
    //                   <i className="fas fa-calendar fa-2x text-gray-300"></i>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-xl-3 col-md-6 mb-4">
    //           <div className="card border-left-success shadow h-100 py-2">
    //             <div className="card-body">
    //               <div className="row no-gutters align-items-center">
    //                 <div className="col mr-2">
    //                   <div
    //                     className="h5 text-xs font-weight-bold text-success text-uppercase mb-2"
    //                     style={{ fontSize: "1rem" }}
    //                   >
    //                     Total Users
    //                   </div>
    //                   <div className="h5 mb-0 font-weight-bold text-gray-800">
    //                     {totalUsers}
    //                   </div>
    //                 </div>
    //                 <div className="col-auto">
    //                   <i className="fas fa-user fa-2x text-gray-300"></i>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-xl-3 col-md-6 mb-4">
    //           <div className="card border-left-info shadow h-100 py-2">
    //             <div className="card-body">
    //               <div className="row no-gutters align-items-center">
    //                 <div className="col mr-2">
    //                   <div
    //                     className="h5 text-xs font-weight-bold text-info text-uppercase mb-2"
    //                     style={{ fontSize: "1rem" }}
    //                   >
    //                     Total Trainee
    //                   </div>
    //                   <div className="h5 mb-0 font-weight-bold text-gray-800">
    //                     {totalTrainee}
    //                   </div>
    //                 </div>
    //                 <div className="col-auto">
    //                   <i className="fas fa-user-graduate fa-2x text-gray-300"></i>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-xl-3 col-md-6 mb-4">
    //           <div className="card border-left-warning shadow h-100 py-2">
    //             <div className="card-body">
    //               <div className="row no-gutters align-items-center">
    //                 <div className="col mr-2">
    //                   <div
    //                     className="h5 text-xs font-weight-bold text-warning text-uppercase mb-2"
    //                     style={{ fontSize: "1rem" }}
    //                   >
    //                     Total Placements
    //                   </div>
    //                   <div className="h5 mb-0 font-weight-bold text-gray-800">
    //                     {totalPlacements}
    //                   </div>
    //                 </div>
    //                 <div className="col-auto">
    //                   <i className="fas fa-user-tie fa-2x text-gray-300"></i>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="row">
    //         <div className="col-lg-8">
    //           <div className="card shadow mb-4">
    //             <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    //               <h6 className="m-0 font-weight-bold text-primary">
    //                 Overview
    //               </h6>
    //               <div className="dropdown no-arrow">
    //                 <a
    //                   className="dropdown-toggle"
    //                   href="#"
    //                   role="button"
    //                   id="dropdownMenuLink"
    //                   data-toggle="dropdown"
    //                   aria-haspopup="true"
    //                   aria-expanded="false"
    //                 >
    //                   <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
    //                 </a>
    //                 <div
    //                   className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
    //                   aria-labelledby="dropdownMenuLink"
    //                 >
    //                   <div className="dropdown-header">Dropdown Header:</div>
    //                   <a className="dropdown-item" href="#">
    //                     Action
    //                   </a>
    //                   <a className="dropdown-item" href="#">
    //                     Another action
    //                   </a>
    //                   <div className="dropdown-divider"></div>
    //                   <a className="dropdown-item" href="#">
    //                     Something else here
    //                   </a>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="card-body">
    //               <div className="chart-area">
    //                 <canvas id="myAreaChart"></canvas>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-lg-4">
    //           <div className="card shadow mb-4">
    //             <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    //               <h6 className="m-0 font-weight-bold text-primary">
    //                 Revenue Sources
    //               </h6>
    //               <div className="dropdown no-arrow">
    //                 <a
    //                   className="dropdown-toggle"
    //                   href="#"
    //                   role="button"
    //                   id="dropdownMenuLink"
    //                   data-toggle="dropdown"
    //                   aria-haspopup="true"
    //                   aria-expanded="false"
    //                 >
    //                   <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
    //                 </a>
    //                 <div
    //                   className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
    //                   aria-labelledby="dropdownMenuLink"
    //                 >
    //                   <div className="dropdown-header">Dropdown Header:</div>
    //                   <a className="dropdown-item" href="#">
    //                     Action
    //                   </a>
    //                   <a className="dropdown-item" href="#">
    //                     Another action
    //                   </a>
    //                   <div className="dropdown-divider"></div>
    //                   <a className="dropdown-item" href="#">
    //                     Something else here
    //                   </a>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="card-body">
    //               <div className="chart-pie pt-4 pb-2">
    //                 <canvas id="myPieChart"></canvas>
    //               </div>
    //               <div className="mt-4 text-center small">
    //                 <span className="mr-2">
    //                   <i className="fas fa-circle text-primary"></i> Direct
    //                 </span>
    //                 <span className="mr-2">
    //                   <i className="fas fa-circle text-success"></i> Social
    //                 </span>
    //                 <span className="mr-2">
    //                   <i className="fas fa-circle text-info"></i> Referral
    //                 </span>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Dashboard;
