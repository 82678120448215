import React from 'react'


const Complaints = () => {
  return (
    <div className='text-center'>
      You are not authorized to access this feature. Please contact to admin.
    </div>
  )
}

export default Complaints